function getFinancialData({
    overview,
    balanceSheet,
    incomeStatement,
    company,
    linkedInJSON,
    dnBFinancialV2Data,
    dnbJsonData,
  }) {
    const formatNumber = (number) => {
      if(!isValid(number)) return null
      if (
        typeof number === "string" &&
        (number.includes("US $") ||
          number.includes("$") ||
          number.includes("billion") ||
          number.includes("million") ||
          number.includes("thousand"))
      ) {
        return number;
      }
    
      const isNegative = number < 0; // Check if the number is negative
      const absoluteValue = Math.abs(number); // Use absolute value for calculations
    
      if (absoluteValue >= 1e9) {
        return `US $${isNegative ? "-" : ""}${(absoluteValue / 1e9).toFixed(2)} billion`;
      } else if (absoluteValue >= 1e6) {
        return `US $${isNegative ? "-" : ""}${(absoluteValue / 1e6).toFixed(2)} million`;
      } else if (absoluteValue >= 1e3) {
        return `US $${isNegative ? "-" : ""}${(absoluteValue / 1e3).toFixed(2)} thousand`;
      } else {
        return `US $${isNegative ? "-" : ""}${absoluteValue}`;
      }
    };
  
    function isValid(value) {
      return (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !Number.isNaN(value)
      );
    }
  
    const WikiInfobox = company.wikipedia?.infobox
      ? JSON.parse(company.wikipedia.infobox)
      : {};
  
    const manualSource = company?.manualSource
      ? JSON.parse(company.manualSource)
      : {};
    var dataYear1 = "";
    var dataYear2 = "";
    var dataYear3 = "";
    var dataYear4 = "";
    var revenueYear1 = "";
    var revenueYear2 = "";
    var revenueYear3 = "";
    let funding = {};
  
    const getDnbRevenueByYear = (dnbFinancials, year) => {
      if (!dnbFinancials) return null;
  
      const previousFinancials = Array.isArray(dnbFinancials?.previousFinancials)
        ? dnbFinancials?.previousFinancials
        : [];
      const allFinancials = [
        dnbFinancials?.latestFinancials,
        ...previousFinancials,
      ];
  
      const matchingFinancial = allFinancials.find(
        (financial) =>
          financial &&
          financial.financialStatementToDate &&
          financial.financialStatementToDate.includes(year) &&
          financial.currency === "USD"
      );
  
      if (matchingFinancial && matchingFinancial.overview) {
        let salesRevenue = matchingFinancial.overview.salesRevenue;
        const units = matchingFinancial.units || "SingleUnits";
  
        if (units === "Million") {
          salesRevenue *= 1000000;
        } else if (units === "Billion") {
          salesRevenue *= 1000000000;
        }
  
        return salesRevenue;
      }
  
      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;
  
      if (year === lastYear && dnbJsonData) {
        const yearlyRevenue =
          dnbJsonData?.organization?.financials?.[0]?.yearlyRevenue || [];
        const usdRevenue = yearlyRevenue.find((item) => item.currency === "USD");
  
        if (usdRevenue) {
          return usdRevenue.value;
        }
      }
  
      return null;
    };
  
    function getRevenue(allSources) {
      const { financialStatement, AV, companySearch, wiki, SF, dnbFinancials } =
        allSources;
      let revenueByYear = {};
      const currentYear = new Date().getFullYear();
      const lastThreeYears = [currentYear - 1, currentYear - 2, currentYear - 3];
      const lastYear = currentYear - 1;
  
      const getRevenueFromSource = (source, year) => {
        if (year === lastYear && manualSource?.revenue) {
          return manualSource?.revenue;
        }
        if (!source) return null;
        switch (source) {
          case "financialStatement":
            return (
              financialStatement?.annualReports?.find((rep) =>
                rep?.fiscalDateEnding?.includes(year)
              )?.totalRevenue ||
              financialStatement?.annualReports?.find((rep) =>
                rep?.fiscalDateEnding?.includes(year)
              )?.salesRevenue ||
              financialStatement?.annualReports?.find((rep) =>
                rep?.fiscalDateEnding?.includes(year)
              )?.netSales
            );
          case "AV":
            return AV?.incomeStatement?.annualReports?.find((rep) =>
              rep?.fiscalDateEnding?.includes(year)
            )?.totalRevenue;
          case "companySearch":
            return companySearch?.financial_data?.data?.revenue?.[year];
          case "wiki":
            if (wiki?.revenue?.manual === true) {
              return wiki.revenue.value;
            } else if (wiki?.revenue) {
              return wiki.revenue;
            }
            return null;
          case "SF":
            return SF?.financial_data?.[year];
          case "dnbFinancials":
            return getDnbRevenueByYear(dnbFinancials?.organization, year);
          default:
            return null;
        }
      };
  
      let avRevenues = [];
      let dnbRevenues = [];
      let avDataAvailable = true;
  
      lastThreeYears.forEach((year) => {
        const avRevenue = getRevenueFromSource("AV", year);
        if (!avRevenue) avDataAvailable = false;
        avRevenues.push(avRevenue);
      });
  
      lastThreeYears.forEach((year) => {
        const dnbRevenue = getRevenueFromSource("dnbFinancials", year);
        dnbRevenues.push(dnbRevenue);
      });
  
      if (!financialStatement && !avDataAvailable) {
        const revenues = [
          getRevenueFromSource("companySearch", currentYear - 1),
          getRevenueFromSource("SF", currentYear - 1),
          getRevenueFromSource("wiki", currentYear - 1),
        ].filter(Boolean);
  
        if (revenues.length > 0) {
          const maxRevenue = Math.max(...revenues);
          const minRevenue = Math.min(...revenues);
  
          if (maxRevenue / minRevenue > 1.25) {
            revenueByYear[currentYear - 1] = `Range: ${formatNumber(
              minRevenue
            )} - ${formatNumber(maxRevenue)}`;
          } else {
            revenueByYear[currentYear - 1] = formatNumber(revenues[0]);
          }
        } else {
          lastThreeYears.forEach((year, index) => {
            const dnbRevenue = dnbRevenues[index];
            if (dnbRevenue) {
              revenueByYear[year] = formatNumber(dnbRevenue);
            } else {
              revenueByYear[year] = null;
            }
          });
        }
      } else {
        lastThreeYears.forEach((year, index) => {
          const financialStatementRevenue = getRevenueFromSource(
            "financialStatement",
            year
          );
          const avRevenue = avRevenues[index];
  
          if (financialStatementRevenue) {
            revenueByYear[year] = formatNumber(financialStatementRevenue);
          } else if (avRevenue) {
            revenueByYear[year] = formatNumber(avRevenue);
          } else if (year === currentYear - 1) {
            const otherRevenues = [
              getRevenueFromSource("companySearch", year),
              getRevenueFromSource("SF", year),
              getRevenueFromSource("wiki", year),
            ].filter(Boolean);
            if (otherRevenues.length > 0) {
              const maxRevenue = Math.max(...otherRevenues);
              const minRevenue = Math.min(...otherRevenues);
  
              if (maxRevenue / minRevenue > 1.25) {
                revenueByYear[year] = `Range: ${formatNumber(
                  minRevenue
                )} - ${formatNumber(maxRevenue)}`;
              } else {
                revenueByYear[year] = formatNumber(otherRevenues[0]);
              }
            } else {
              revenueByYear[year] = null;
            }
          }
        });
      }
      return revenueByYear;
    }
  
    const revenues = getRevenue({
      AV: { overview, balanceSheet, incomeStatement },
      wiki: WikiInfobox,
      dnbFinancials: dnBFinancialV2Data,
    });
    const currentYear = new Date().getFullYear();
    revenueYear1 = revenues[currentYear - 1];
    revenueYear2 = revenues[currentYear - 2];
    revenueYear3 = revenues[currentYear - 3];
  
    if (incomeStatement) {
      // todo - source mapping FE - add financial statement, companysearch, SF
      dataYear1 = incomeStatement?.annualReports[0];
  
      dataYear2 = incomeStatement?.annualReports[1];
  
      dataYear3 = incomeStatement?.annualReports[2];
  
      dataYear4 = incomeStatement?.annualReports[3];
    }
  
    const financialStatement = null; // todo - replace with original financialStatement
    let netProfit = null;
    //financial statement.Net income
    if (financialStatement) {
      netProfit = financialStatement?.NetIncome;
    }
    //AV.incomeStatement.annualReports.netIncome
    else if (incomeStatement) {
      netProfit = incomeStatement?.annualReports[0]?.netIncome;
    }
    //wikipedia.infobox.net_income	int
    else if (WikiInfobox) {
      netProfit = WikiInfobox.net_income;
    }
  
    //3	Market value
    let marketValue = null;
    //AV.overview.MarketCapitalization
    if (overview) {
      marketValue = overview.MarketCapitalization;
    } else if (financialStatement) {
      // financial statement(Financial variables).MarketCap
      marketValue = financialStatement.MarketCap;
    } else if (WikiInfobox) {
      // wikipedia.infobox.MarketCap
      marketValue = WikiInfobox.MarketCap;
    }
  
    //4	Operating income
    let operatingIncome = null;
    //financial statement
    if (financialStatement) {
      operatingIncome = financialStatement?.OperatingProfit;
    }
    //AV.incomeStatement.annualReports.operatingIncome
    else if (incomeStatement) {
      operatingIncome = incomeStatement?.annualReports[0]?.operatingIncome;
    }
    //wikipedia.infobox.operating_income
    else if (WikiInfobox) {
      operatingIncome = WikiInfobox.operating_income;
    }
  
    //5	Net interest expense
    let netInterestExpense = null;
    //financial statement
    if (financialStatement) {
      netInterestExpense = null;
    }
    //AV.incomeStatement.annualReports.interestExpense
    else if (incomeStatement) {
      netInterestExpense = incomeStatement?.annualReports[0]?.interestExpense;
    }
  
    //6	EBITDA
    let ebitda = null;
    //financial statement
    if (financialStatement) {
      ebitda = null;
    }
    //AV.incomeStatement.annualReports.ebitda
    else if (incomeStatement) {
      ebitda = incomeStatement?.annualReports[0]?.ebitda;
    }
  
    //7	Net income
    let netIncome = null;
    //financial statement
    if (financialStatement) {
      netIncome = 0;
    }
    //AV.incomeStatement.annualReports.netincome
    else if (incomeStatement) {
      netIncome = incomeStatement?.annualReports[0]?.netIncome;
    }
    //response.organization.latestFinancials.overview.netIncome
    else if (dnBFinancialV2Data) {
      netIncome =
        dnBFinancialV2Data?.organization?.latestFinancials?.overview?.netIncome;
    }
    //wikipedia.infobox.net_income
    else if (WikiInfobox) {
      netIncome = WikiInfobox?.net_income;
    }
  
    //8	Cash and cash equivalents
    let CashAndCashEquivalents = null;
    //financial statement
    if (financialStatement) {
      CashAndCashEquivalents = null;
    }
    //AV.balanceSheet.annualReports.cashAndCashEquivalentsAtCarryingValue
    else if (incomeStatement) {
      CashAndCashEquivalents =
        balanceSheet.annualReports[0]?.cashAndCashEquivalentsAtCarryingValue;
    }
    //response.organization.latestFinancials.overview.cashAndLiquidAssets
    else if (dnBFinancialV2Data) {
      CashAndCashEquivalents =
        dnBFinancialV2Data?.organization?.latestFinancials?.overview
          ?.cashAndLiquidAssets;
    }
    //9	Total Assets
    let totalAssets = null;
    //financial statement
    if (financialStatement) {
      totalAssets = null;
    }
    //AV.balanceSheet.annualReports.totalAssets
    else if (balanceSheet) {
      totalAssets = balanceSheet?.annualReports[0]?.totalAssets;
    }
    //response.organization.latestFinancials.overview.totalAssets	wikipedia.infobox.assets
    else if (dnBFinancialV2Data) {
      totalAssets =
        dnBFinancialV2Data?.organization?.latestFinancials?.overview
          ?.totalAssets || "";
    } else if (WikiInfobox) {
      totalAssets = WikiInfobox?.totalAssets;
    }
  
    //10	Total Assets - last year
    let totalAssetsLastYear = null;
    //financial statement
    if (financialStatement) {
      totalAssetsLastYear = null;
    }
    //AV.balanceSheet.annualReports.totalAssets
    else if (balanceSheet) {
      totalAssetsLastYear = balanceSheet.annualReports[1]?.totalAssets;
    }
    //response.organization.previousFinancials[0].overview.cashAndLiquidAssets
    else if (dnBFinancialV2Data) {
      totalAssetsLastYear =
        dnBFinancialV2Data?.organization?.previousFinancials[0]?.overview
          .cashAndLiquidAssets; // dnbFinancials.organization.previousFinancials[0].overview.totalAssets
    }
    //11	Short term debt
    let ShortTermDebt = null;
    //financial statement
    if (financialStatement) {
      ShortTermDebt = null;
    }
    //AV.balanceSheet.annualReports.shortTermDebt
    else if (balanceSheet) {
      ShortTermDebt = balanceSheet.annualReports[0]?.shortTermDebt;
    }
    console.log("🚀 ~ shortTermDebt:", ShortTermDebt)
  
    //12	Long term debt
    let longTermDebt = null;
  
    // Stock
  
    //financial statement
    if (financialStatement) {
      longTermDebt = null;
    }
    //AV.balanceSheet.annualReports.longTermDebt
    else if (balanceSheet) {
      longTermDebt = balanceSheet.annualReports[0]?.longTermDebt;
    }
    //response.organization.latestFinancials.overview.longTermDebt
    else if (dnBFinancialV2Data) {
      longTermDebt =
        dnBFinancialV2Data?.organization?.latestFinancials?.overview
          ?.longTermDebt || "";
    }
    //13	Total Liabilities
    let TotalLiabilities = null;
    //financial statement
    if (financialStatement) {
      TotalLiabilities = null;
    }
    //AV.balanceSheet.annualReports.totalLiabilities
    else if (balanceSheet) {
      TotalLiabilities = balanceSheet.annualReports[0]?.totalLiabilities;
    }
    //response.organization.latestFinancials.overview.totalLiabilities
    else if (dnBFinancialV2Data) {
      TotalLiabilities =
        dnBFinancialV2Data?.organization?.latestFinancials?.overview
          ?.totalLiabilities;
    }
    //14	Equity
    let Equity = null;
    //financial statement
    if (financialStatement) {
      Equity = null;
    }
    //AV.balanceSheet.annualReports.totalShareholderEquity
    else if (balanceSheet) {
      Equity = balanceSheet.annualReports[0]?.totalShareholderEquity;
    } else if (WikiInfobox) {
      Equity = WikiInfobox?.equity;
    }
  
    //15	Equity - last year
    let EquityLastYear = null;
    //financial statement
    if (financialStatement) {
      EquityLastYear = null;
    }
    //AV.balanceSheet.annualReports.totalShareholderEquity
    else if (balanceSheet) {
      EquityLastYear = balanceSheet.annualReports[1]?.totalShareholderEquity;
    }
  
    //16	report date
    let reportDate = null;
    //financial statement
    if (financialStatement) {
      reportDate = null;
    }
    //AV.incomeStatement.annualReports.fiscalDateEnding
    else if (incomeStatement) {
      reportDate = incomeStatement.annualReports[0]?.fiscalDateEnding;
    }
    //response.organization.latestFinancials.financialStatementToDate
    else if (dnBFinancialV2Data) {
      reportDate =
        dnBFinancialV2Data?.organization?.latestFinancials
          ?.financialStatementToDate;
    }
    // Funding todo - add zoominfo data here
    funding = linkedInJSON?.funding;
    const getUnit = (number) => {
      if (number >= 1e9) {
        return { unit: `USD Billion`, unitValue: 1e9 };
      } else if (number >= 1e6) {
        return { unit: `USD Million`, unitValue: 1e6 };
      } else if (number >= 1e3) {
        return { unit: `USD Thousand`, unitValue: 1e3 };
      } else {
        return { unit: `USD`, unitValue: 1 };
      }
    };
  
    function createData(name, TTM, year1, year2, year3, year4) {
      const min = Math.min(year1, year2, year3, year4);
  
      const { unit, unitValue } = getUnit(min);
      year1 = year1 / unitValue;
      year2 = year2 / unitValue;
      year3 = year3 / unitValue;
      year4 = year4 / unitValue;
  
      return { name: `${name} (${unit})`, TTM, year1, year2, year3, year4 };
    }
  
    //TTM //last 3 years
    const yearlyRows = [
      createData(
        "Total Revenue",
        "-",
        dataYear1?.totalRevenue,
        dataYear2?.totalRevenue,
        dataYear3?.totalRevenue,
        dataYear4?.totalRevenue
      ),
      createData(
        "Cost of Revenue",
        "-",
        dataYear1?.costOfRevenue,
        dataYear2?.costOfRevenue,
        dataYear3?.costOfRevenue,
        dataYear4?.costOfRevenue
      ),
      createData(
        "Gross Profit",
        "-",
        dataYear1?.grossProfit,
        dataYear2?.grossProfit,
        dataYear3?.grossProfit,
        dataYear4?.grossProfit
      ),
      createData(
        "Operating Expense",
        "-",
        dataYear1?.operatingExpenses,
        dataYear2?.operatingExpenses,
        dataYear3?.operatingExpenses,
        dataYear4?.operatingExpenses
      ),
    ];
  
    var dataYearQ1 = "";
    var dataYearQ2 = "";
    var dataYearQ3 = "";
    var dataYearQ4 = "";
  
    if (overview && incomeStatement && balanceSheet) {
      dataYearQ1 = incomeStatement?.quarterlyReports[0];
      dataYearQ2 = incomeStatement?.quarterlyReports[1];
      dataYearQ3 = incomeStatement?.quarterlyReports[2];
      dataYearQ4 = incomeStatement?.quarterlyReports[3];
    }
  
    const headingStyle = {
      fontSize: "15px",
    };
  
    const valueStyle = {
      fontSize: "14px",
      fontWeight: "600",
    };
  
    function createQuaterData(name, Q1, Q2, Q3, Q4) {
      const min = Math.min(Q1, Q2, Q3, Q4);
  
      const { unit, unitValue } = getUnit(min);
      Q1 = Q1 / unitValue;
      Q2 = Q2 / unitValue;
      Q3 = Q3 / unitValue;
      Q4 = Q4 / unitValue;
  
      return { name: `${name} (${unit})`, Q1, Q2, Q3, Q4 };
    }
  
    const QuaterRows = [
      createQuaterData(
        "Total Revenue",
        dataYearQ1?.totalRevenue,
        dataYearQ2?.totalRevenue,
        dataYearQ3?.totalRevenue,
        dataYearQ4?.totalRevenue
      ),
      createQuaterData(
        "Cost of Revenue",
        dataYearQ1?.costOfRevenue,
        dataYearQ2?.costOfRevenue,
        dataYearQ3?.costOfRevenue,
        dataYearQ4?.costOfRevenue
      ),
      createQuaterData(
        "Gross Profit",
        dataYearQ1?.grossProfit,
        dataYearQ2?.grossProfit,
        dataYearQ3?.grossProfit,
        dataYearQ4?.grossProfit
      ),
      createQuaterData(
        "Operating Expense",
        dataYearQ1?.operatingExpenses,
        dataYearQ2?.operatingExpenses,
        dataYearQ3?.operatingExpenses,
        dataYearQ4?.operatingExpenses
      ),
    ];
  
    const assets = (totalAssets + totalAssetsLastYear) * 0.5;
    const ROA = ((netIncome / assets) * 100).toFixed(2);
    // ROE	calculated	Net income/ 50%*(equity+equity previous year)
    const ROE = ((netIncome / (0.5 * (Equity + EquityLastYear))) * 100).toFixed(
      2
    );
  
    const financeInfoVars = [
      revenueYear1,
      revenueYear2,
      revenueYear3,
      incomeStatement?.annualReports[0].operatingIncome,
      TotalLiabilities,
      reportDate,
      totalAssets,
      totalAssetsLastYear,
      marketValue,
      netInterestExpense,
      ebitda,
      netIncome,
      CashAndCashEquivalents,
      netProfit,
      Equity,
      EquityLastYear,
      ShortTermDebt,
      longTermDebt,
    ];
  
    const isAllFinanceInfoVarsInvalid = financeInfoVars.every(
      (v) => v === undefined || v === null || isNaN(v) || v === ""
    );
  
    const profitabilityRatioVars = [
      ROA,
      ROE,
      operatingIncome / revenueYear1,
      netIncome / revenueYear1,
    ];
  
    let isAllPRVarsInvalid = profitabilityRatioVars.every(
      (v) => v === undefined || v === null || isNaN(v) || v === "" || v === "0" || v === "0.00"
    );
  
    const flattenedValuesQR = QuaterRows.map((obj) => Object.values(obj)).flat();
  
    const quarterlyReviewVars = [
      dataYearQ1?.fiscalDateEnding,
      dataYearQ2?.fiscalDateEnding,
      dataYearQ3?.fiscalDateEnding,
      dataYearQ4?.fiscalDateEnding,
      ...flattenedValuesQR,
    ];
  
    let isAllQRVarsInvalid = quarterlyReviewVars.every(
      (v) => v === undefined || v === null || isNaN(v) || v === ""
    );
  
    const flattenedValuesYR = yearlyRows.map((obj) => Object.values(obj)).flat();
  
    const yearlyReviewVars = [
      dataYearQ1?.fiscalDateEnding,
      dataYearQ2?.fiscalDateEnding,
      dataYearQ3?.fiscalDateEnding,
      dataYearQ4?.fiscalDateEnding,
      ...flattenedValuesYR,
    ];
  
    let isAllYRVarsInvalid = yearlyReviewVars.every(
      (v) => v === undefined || v === null || isNaN(v) || v === ""
    );
  
    var financialData = {
      // Revenue for the last 3 years
  
      revenue: {
        year1: revenueYear1,
        year2: revenueYear2,
        year3: revenueYear3,
      },
  
      isAllPRVarsInvalid,
      isAllFinanceInfoVarsInvalid,
      isAllYRVarsInvalid,
      isAllQRVarsInvalid,
  
      // Net Profit
      netProfit: formatNumber(netProfit),
  
      // Market Value
      marketValue: formatNumber(marketValue),
  
      // Operating Income
      operatingIncome: formatNumber(operatingIncome),
  
      // Net Interest Expense
      netInterestExpense: formatNumber(netInterestExpense),
  
      // EBITDA
      ebitda: formatNumber(ebitda),
  
      // Net Income
      netIncome: formatNumber(netIncome),
  
      // Cash and Cash Equivalents
      cashAndCashEquivalents: formatNumber(CashAndCashEquivalents),
  
      // Total Assets
      totalAssets: formatNumber(totalAssets),
      totalAssetsLastYear: formatNumber(totalAssetsLastYear),
  
      // Short Term Debt
      shortTermDebt: formatNumber(ShortTermDebt),
  
      // Long Term Debt
      longTermDebt: formatNumber(longTermDebt),
  
      // Total Liabilities
      totalLiabilities: formatNumber(TotalLiabilities),
  
      // Equity
      equity: formatNumber(Equity),
      equityLastYear: formatNumber(EquityLastYear),
  
      // Report Date
      reportDate: reportDate,
  
      ROA,
      ROE,
      // Profitability Ratios
      profitabilityRatios: {
        ROA: !isNaN(ROA) ? ROA : "-",
        ROE: !isNaN(ROE) ? ROE : "-",
        operatingIncomeToRevenue: !isNaN(
          ((operatingIncome / revenueYear1) * 100).toFixed(2)
        )
          ? ((operatingIncome / revenueYear1) * 100).toFixed(2)
          : "",
        netIncomeToRevenue: !isNaN(((netIncome / revenueYear1) * 100).toFixed(2))
          ? ((netIncome / revenueYear1) * 100).toFixed(2)
          : "",
      },
  
      // Yearly Data
      yearlyData: {
          rows: yearlyRows.map((row) => ({
          name: row.name,
          TTM: row.TTM,
          year1: row.year1,
          year2: row.year2,
          year3: row.year3,
          year4: row.year4,
        })),
        dataYear1,
        dataYear2,
        dataYear3,
        dataYear4,
      },
      // Quarterly Data
      quarterlyData: {
        rows: QuaterRows.map((row) => ({
        name: row.name,
        Q1: row.Q1,
        Q2: row.Q2,
        Q3: row.Q3,
        Q4: row.Q4,
      })),
      fiscalDateEndingQ1:dataYearQ1?.fiscalDateEnding,
      fiscalDateEndingQ2:dataYearQ2?.fiscalDateEnding,
      fiscalDateEndingQ3:dataYearQ3?.fiscalDateEnding,
      fiscalDateEndingQ4:dataYearQ4?.fiscalDateEnding,
    }
  };
  
  
    return financialData;
  }
  
  export default getFinancialData;
  