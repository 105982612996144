import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import awsExports from "./aws-exports";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import Close from "./Assets/Images/close.svg";
import { toast } from "react-toastify";
import { uploadFileToS3 } from "./utils/Helper";
import { useDropzone } from "react-dropzone";
import {
  AgingSummaryColumnMapper,
  HistoricalPaymentColumnMapper,
  identifyFile,
  mergeFiles,
} from "./utils/fileProcessingUtils";
import BinIcon from "./Assets/Images/delete.svg";
import Tick from "./Assets/Images/tick.svg";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";
import dayjs from 'dayjs';
import {
  convertAgingAndPaymentFile,
  convertExlFilesPapaya,
} from "./graphql/queries";
import { updateTenant } from "./graphql/mutations";

export const validateColumnMapping = (mapping, requiredFields) => {
  return requiredFields.every((field) => mapping[field]);
};

Amplify.configure(awsExports);
Amplify.configure(awsconfig);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "833px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
};

const modalStyle = {
  backdropFilter: "blur(2px)",
  backgroundColor: "rgb(255, 255, 255, 0.5)",
};

const stepperStyle = {
  background: "#5186EC1A",
  height: "82px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const dropzoneStyle = {
  width: "60%",
  border: "2px dashed #5186EC",
  padding: "20px",
  borderRadius: "8px",
  textAlign: "center",
  cursor: "pointer",
  margin: "20px auto",
};

const buttonStyle = {
  mt: 2,
  backgroundColor: "#5186EC",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#4069D0",
  },
  borderRadius: "8px",
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#E9E9E9",
  zIndex: 1,
  color: "#fff",
  width: 45,
  height: 45,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 3px 6px #00000029",
  ...(ownerState.active && {
    backgroundColor: "#2F3D63",
    boxShadow: "0px 3px 6px #00000029",
    border: "2px solid #5186EC",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#2F3D63",
    boxShadow: "0px 3px 6px #00000029",
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}>
      {completed ? (
        <img src={Tick} width={25} alt='' />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <span
            style={{
              color: active ? "#fff" : "#777A82",
              fontSize: "18px",
              fontWeight: "500",
            }}>
            {icon}
          </span>
        </div>
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const FileUploadModal = (props) => {
  const { open, close } = props;
  const [files, setFiles] = useState([]);
  const [tenantId, setTenantId] = useState();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fileTypes, setFileTypes] = useState({});
  const [columnMappings, setColumnMappings] = useState([]);
  const [dateSelections, setDateSelections] = useState({});
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [agingUpdatedUpTo, setAgingUpdatedUpTo] = useState(null);

  const steps = ["Select Files", "Confirm Files", "Completed"];

  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const URLTenant = getTenantIdFromURL();

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };
  const handleAccordionChange = (fileName) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? fileName : false);
  };

  const handleDateChange = (newDate) => {
    if (currentFile) {
      const selectedDate = new Date(newDate);
      const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
      
      console.log("Aging Updated Up To:", agingUpdatedUpTo);
      console.log("selectedDate (UTC):", utcDate);

        if (utcDate && utcDate < new Date(agingUpdatedUpTo)) {
            toast.error(`The selected date for ${currentFile} must be on or after ${new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' }).format(new Date(agingUpdatedUpTo))}.`);
            return;
        }
        setDateSelections((prev) => ({ ...prev, [currentFile]: utcDate.toISOString() }));
    }
    setOpenDatePicker(false);
  };

  const handleCalendarClick = (fileName) => {
    setCurrentFile(fileName);
    setOpenDatePicker(true);
  };

  const getLatestDate = () => {
    const selectedDates = Object.values(dateSelections).filter((date) => date);

    const latestDate =
      selectedDates.length === 0
        ? new Date()
        : selectedDates.reduce((latest, current) =>
            new Date(current) > new Date(latest) ? current : latest
          );

    return formatDate(latestDate);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}_${month}_${year}`;
  };

  const handleClose = () => {
    setFiles([]);
    setFileTypes({});
    setColumnMappings([]);
    setDateSelections({});
    setActiveStep(0);
    setLoading(false); 
    setCurrentFile(null); 
    setOpenDatePicker(false); 
    close();
  };

  useEffect(() => {
    selectTenantId();
  }, [isAdmin, currentTenant, URLTenant]);
  useEffect(() => {
    const fetchTenantInfo = async () => {
      const { agingUpdatedUpTo } = await getTenantInfo();
      setAgingUpdatedUpTo(agingUpdatedUpTo);
    };
    fetchTenantInfo();
  }, []);

  const onDrop = async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const fileType = await identifyFile(file);
      setFileTypes((prevTypes) => ({ ...prevTypes, [file.name]: fileType }));
    }
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.filter(
        (newFile) =>
          !prevFiles.some((existingFile) => existingFile.name === newFile.name)
      ),
    ]);
  };

  const handleFileTypeChange = (fileName, newType) => {
    setFileTypes((prevTypes) => ({ ...prevTypes, [fileName]: newType }));
  };

  const handleDeleteFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setDateSelections((prevSelections) => {
      const newSelections = { ...prevSelections };
      delete newSelections[fileName];
      return newSelections;
    })
    setFileTypes((prevTypes) => {
      const newTypes = { ...prevTypes };
      delete newTypes[fileName];
      return newTypes;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv, .xls, .xlsx",
    multiple: true,
  });

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2F3D63",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2F3D63",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 0,
      border: "1px solid #2F3D63",
      backgroundColor: "#2F3D63",
      borderRadius: 1,
    },
  }));

  const uploadFile = async (file) => {
    try {
      const key = `public/aging-json-files/${tenantId}/${file.name}`;
      const contentType = file.type;
      const result = await uploadFileToS3(key, file, contentType);
      return { key, result };
    } catch (error) {
      console.error("Error uploading file: ", error);
      toast.error(`Error uploading ${file.name}`);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      if (
        tenantId === "300358df-22a8-471b-863e-2bb84b5ef948" ||
        tenantId === "notenant"
      ) {
        const agingFile = files.find(file => fileTypes[file.name] === "Aging Summary");
        const paymentFile = files.find(file => fileTypes[file.name] === "Historical Payment and Invoices");

        const newAgingFile = new File(
          [await agingFile.arrayBuffer()],
          `${getLatestDate()}_aging_data.csv`,
          { type: "text/csv" }
        );

        const newPaymentFile = new File(
          [await paymentFile.arrayBuffer()],
          `${getLatestDate()}_payment_data.csv`,
          { type: "text/csv" }
        );

        const agingRes = await uploadFile(newAgingFile);
        const paymentRes = await uploadFile(newPaymentFile);

        await API.graphql(
          graphqlOperation(convertExlFilesPapaya, {
            tenantId: tenantId,
            agingKey: agingRes.key,
            paymentKey: paymentRes.key,
          })
        );

        setActiveStep(2);

      } else {
        const { agingData, paymentData } = await mergeFiles(
          files,
          fileTypes,
          columnMappings,
          dateSelections
        );

        const agingFile = new File(
          [JSON.stringify(agingData)],
          `${getLatestDate()}_aging_data.json`,
          { type: "application/json" }
        );
        const paymentFile = new File(
          [JSON.stringify(paymentData)],
          `${getLatestDate()}_payment_data.json`,
          { type: "application/json" }
        );

        const agingRes = await uploadFile(agingFile);
        const paymentRes = await uploadFile(paymentFile);

        await API.graphql(
          graphqlOperation(convertAgingAndPaymentFile, {
            tenantId: tenantId,
            agingKey: agingRes.key,
            paymentKey: paymentRes.key,
          })
        );

        toast.success(`Files uploaded successfully!`);
        setActiveStep(2);
      }

      const latestDate = getLatestDate();
      const formattedDate = latestDate.split("_").reverse().join("-");
      await API.graphql(
        graphqlOperation(updateTenant, {
          input: {
            id: tenantId,
            agingUpdatedUpTo: new Date(formattedDate).toISOString(),
          },
        })
      );

    } catch (error) {
      console.error("Error during file upload: ", error);
      toast.error(error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (file) => {
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleNext = () => {
    const agingFilesWithoutDate = files.some(
      (file) => fileTypes[file.name] === "Aging Summary" && !dateSelections[file.name]
    );
  
    if (activeStep === 0) {
      const agingFilesCount = files.filter(file => fileTypes[file.name] === "Aging Summary").length;
      const paymentFilesCount = files.filter(file => fileTypes[file.name] === "Historical Payment and Invoices").length;
  
      if (agingFilesCount !== paymentFilesCount) {
        toast.error("The number of Aging Summary files must equal the number of Historical Payment and Invoices files.");
        return;
      }
  
      if (agingFilesWithoutDate) {
        toast.error("Please select a date for all Aging Summary files.");
        return;
      }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleButtonClick = () => {
    if (activeStep === 0) {
      handleNext();
    } else if (activeStep === 1) {
      handleUpload();
    } else if (activeStep === 2) {
      handleClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='file-upload-modal-title'
        aria-describedby='file-upload-modal-description'
        sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            border: "none",
          }}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleClose();
            }
          }}>
          <Box sx={style}>
            <IconButton
              sx={{ position: "absolute", top: 10, left: 10 }}
              onClick={handleClose}>
              <img src={Close} alt='close button' />
            </IconButton>
            <Typography
              id='file-upload-modal-title'
              variant='h6'
              component='h2'
              sx={{
                textAlign: "center",
                padding: 2,
                color: "#2F3D63",
              }}>
              Add Files
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
              sx={stepperStyle}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    icon={index + 1}></StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box
              sx={{
                margin: "20px auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {activeStep === 0 && (
                <Box sx={{ width: "90%" }}>
                  <Box {...getRootProps()} sx={dropzoneStyle}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <Typography variant='body1' color='primary'>
                        Drop the files here...
                      </Typography>
                    ) : (
                      <Typography variant='body1' color='primary'>
                        Drag and drop files here, or click to select files
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      textAlign: "left",
                      width: "100%",
                      color: "#2F3D63",
                      fontWeight: "bold",
                    }}>
                    {files.map((file, index) => (
                      <Box
                        key={file.name}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                          justifyContent: "space-between",
                        }}>
                        <Typography variant='heading2' sx={{ mr: 2 }}>
                          {index + 1}. {file.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                          }}>
                          <FormControl
                            variant='outlined'
                            size='small'
                            sx={{ mr: 1, minWidth: "212px" }}>
                            <InputLabel id={`file-type-label-${file.name}`}>
                              File Type
                            </InputLabel>
                            <Select
                              labelId={`file-type-label-${file.name}`}
                              value={fileTypes[file.name] || ""}
                              onChange={(event) =>
                                handleFileTypeChange(
                                  file.name,
                                  event.target.value
                                )
                              }
                              label='File Type'
                              sx={{
                                height: "40px",
                                color: "#2F3D63",
                              }}>
                              <MenuItem
                                value='Aging Summary'
                                sx={{ width: "210px" }}>
                                Aging Summary
                              </MenuItem>
                              <MenuItem value='Historical Payment and Invoices'>
                                Historical Payment and Invoices
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {fileTypes[file.name] === "Aging Summary" && (
                            <>
                              <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => handleCalendarClick(file.name)}
                                sx={{
                                  mr: 1,
                                  height: "40px",
                                }}>
                                {dateSelections[file.name] ? (
                                  <EventAvailableIcon /> 
                                ) : (
                                  <CalendarTodayIcon /> 
                                )}
                              </Button>
                            </>
                          )}
                          <Button
                            variant='outlined'
                            color='error'
                            size='small'
                            onClick={() => handleDeleteFile(file.name)}
                            sx={{
                              minWidth: "auto",
                              padding: "4px",
                              height: "40px",
                            }}>
                            <img
                              src={BinIcon}
                              alt='Delete'
                              style={{ width: "16px", height: "16px" }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {openDatePicker && (
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      onAccept={handleDateChange}
                      value={dateSelections[currentFile] ? dayjs(dateSelections[currentFile]) : null}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ display: "none" }} />
                      )}
                    />
                  )}
                </Box>
              )}

              {activeStep === 1 && (
                <Box sx={{ width: "90%" }}>
                  <Typography
                    variant='body1'
                    sx={{ mb: 2, color: "#2F3D63", fontSize: "12px" }}>
                    In order to process the file, we kindly ask for your
                    assistance in ensuring that the columns are correctly
                    identified. Your help is greatly appreciated.
                  </Typography>

                  {files.map((file, index) => {
                    const fileType = fileTypes[file.name];
                    return (
                      <Accordion
                        key={file.name}
                        sx={{ mb: 2 }}
                        expanded={expandedAccordion === file.name}
                        onChange={handleAccordionChange(file.name)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${file.name}-content`}
                          id={`${file.name}-header`}>
                          <Typography variant='p' sx={{ color: "#2F3D63" }}>
                            <span style={{ fontWeight: "bold" }}>
                              {index + 1}. {file.name}
                            </span>{" "}
                            - {fileType}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ maxHeight: "250px", overflowY: "auto" }}>
                          {fileType === "Aging Summary" && (
                            <AgingSummaryColumnMapper
                              file={file}
                              setColumnMappings={setColumnMappings}
                            />
                          )}
                          {fileType === "Historical Payment and Invoices" && (
                            <HistoricalPaymentColumnMapper
                              file={file}
                              setColumnMappings={setColumnMappings}
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              )}

              {activeStep === 2 && (
                <Typography variant='body2' color='primary' sx={{ mt: 2 }}>
                  Files uploaded successfully!
                </Typography>
              )}
              <Button
                variant='outlined'
                sx={{
                  ...buttonStyle,
                  backgroundColor: "#fff",
                  color: "#5186EC",
                  borderColor: "#5186EC",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    borderColor: "#4069D0",
                  },
                }}
                disabled={activeStep === 0}
                onClick={handleBack}>
                Back
              </Button>
              <Button
                variant='contained'
                sx={{
                  ...buttonStyle,
                  backgroundColor: files.length === 0 ? "#f0f0f0" : "#5186EC",
                  color: files.length === 0 ? "#9F9F9F" : "#fff",
                  "&:hover": {
                    backgroundColor: files.length === 0 ? "#f0f0f0" : "#4069D0",
                  },
                }}
                disabled={files.length === 0 || loading || activeStep === 3}
                onClick={handleButtonClick}>
                {activeStep === 0
                  ? "Next"
                  : activeStep === 1
                  ? "Upload"
                  : "Close"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default FileUploadModal;
