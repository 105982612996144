import { ColorEnum } from "../Assets/Colors/ColorsEnum";

export const LocalStyle = {
  dataGrid: {
    border: "none",
    fontFamily: "Rubik, sans-serif",
    color: ColorEnum.DARK_BLUE_SHADE3,
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#80AAC11A",
      // fontSize: "14px",
      color: ColorEnum.DARK_BLUE_SHADE3,
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',  
    },
    '& .MuiDataGrid-cell:first-child': {
      outline : 'none',
    },
    '& .MuiDataGrid-cell:last-child': {
      outline : 'none',
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-row:nth-of-type(even)": {
      backgroundColor: "#FAFAFC",
    },
    "& .MuiDataGrid-row:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "1.5",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    '& .MuiDataGrid-cell[data-field="Company Name"]': {
      textDecoration: "underline",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      // lineHeight: '17px',
    },
    '& .MuiDataGrid-cell[data-field="Recommended Limit"]': {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#E5EEFA" 
    },
    //   "& .MuiDataGrid-cell[data-colindex='0'], & .MuiDataGrid-columnHeader[data-colindex='0']": {
    //   display: 'none'
    // },
    // "& .MuiDataGrid-detailPanelToggleCell, & .MuiDataGrid-columnHeader[data-field='__detail_panel_toggle__']": {
    //   maxWidth: 10,
    // },
    // "& .MuiDataGrid-detailPanelToggleCell > button": {
    //   width: 10,
    // },
    "& .MuiDataGrid-virtualScroller": {
      "&::-webkit-scrollbar": {
        display: "none", // hides scrollbar for WebKit browsers
      },
      scrollbarWidth: "none", // hides scrollbar for Firefox
    },
  },
};

export const MitigationOptions=[
  { key: "Financial Statement", value: "Financial Statement" },
  { key: "Comfort letter", value: "Comfort letter" },
  { key: "Parent company guarantee", value: "Parent company guarantee" },
  { key: "Owners' guarantee", value: "Owners' guarantee" },
  { key: "Government guarantee", value: "Government guarantee" },
  { key: "Insurance", value: "Insurance" },
  { key: "Collateral", value: "Collateral" }
]