/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const triggerQuestionsAgent = /* GraphQL */ `
  query TriggerQuestionsAgent($tenantId: ID, $id: ID) {
    triggerQuestionsAgent(tenantId: $tenantId, id: $id)
  }
`;
export const dnbSearchCompany = /* GraphQL */ `
  query DnbSearchCompany(
    $name: String!
    $tenantId: ID
    $isPaid: Boolean
    $searchType: String
    $registrationNumber: String
    $iso2Code: String
  ) {
    dnbSearchCompany(
      name: $name
      tenantId: $tenantId
      isPaid: $isPaid
      searchType: $searchType
      registrationNumber: $registrationNumber
      iso2Code: $iso2Code
    )
  }
`;
export const dnbGetCompanyInfo = /* GraphQL */ `
  query DnbGetCompanyInfo(
    $id: String!
    $tenantId: ID
    $isPaid: Boolean
    $counter: Int
  ) {
    dnbGetCompanyInfo(
      id: $id
      tenantId: $tenantId
      isPaid: $isPaid
      counter: $counter
    )
  }
`;
export const buyerUnderwriting = /* GraphQL */ `
  query BuyerUnderwriting($tenantId: ID, $id: ID!) {
    buyerUnderwriting(tenantId: $tenantId, id: $id)
  }
`;
export const triggerSendEvaluationEmail = /* GraphQL */ `
  query TriggerSendEvaluationEmail(
    $tenantId: ID
    $tenantName: String!
    $id: ID!
    $companyName: String!
    $requestedAmount: String!
    $comments: String!
    $fullName: String
    $title: String
    $email: String
    $phoneNumber: String
  ) {
    triggerSendEvaluationEmail(
      tenantId: $tenantId
      tenantName: $tenantName
      id: $id
      companyName: $companyName
      requestedAmount: $requestedAmount
      comments: $comments
      fullName: $fullName
      title: $title
      email: $email
      phoneNumber: $phoneNumber
    )
  }
`;
export const addNewsLink = /* GraphQL */ `
  query AddNewsLink($url: String!, $tenantId: ID!, $googleSearchNewsId: ID!) {
    addNewsLink(
      url: $url
      tenantId: $tenantId
      googleSearchNewsId: $googleSearchNewsId
    )
  }
`;
export const addCustomMetric = /* GraphQL */ `
  query AddCustomMetric($tenantId: ID!) {
    addCustomMetric(tenantId: $tenantId)
  }
`;
export const getSECCompanyInfo = /* GraphQL */ `
  query GetSECCompanyInfo($companyInfo: AWSJSON!) {
    getSECCompanyInfo(companyInfo: $companyInfo)
  }
`;
export const getFillingLinks = /* GraphQL */ `
  query GetFillingLinks($companyInfo: AWSJSON!) {
    getFillingLinks(companyInfo: $companyInfo)
  }
`;
export const getCompanySummary = /* GraphQL */ `
  query GetCompanySummary($event: AWSJSON!) {
    getCompanySummary(event: $event)
  }
`;
export const getCompanyPDFInfo = /* GraphQL */ `
  query GetCompanyPDFInfo($url: String, $companyID: String, $tenantId: ID) {
    getCompanyPDFInfo(url: $url, companyID: $companyID, tenantId: $tenantId)
  }
`;
export const addCompanyByAgingFile = /* GraphQL */ `
  query AddCompanyByAgingFile($tenantId: ID, $fileName: String) {
    addCompanyByAgingFile(tenantId: $tenantId, fileName: $fileName)
  }
`;
export const getCompanyNewsContent = /* GraphQL */ `
  query GetCompanyNewsContent($url: String, $content: String) {
    getCompanyNewsContent(url: $url, content: $content)
  }
`;
export const convertAgingAndPaymentFile = /* GraphQL */ `
  query ConvertAgingAndPaymentFile(
    $tenantId: ID
    $agingKey: String
    $paymentKey: String
  ) {
    convertAgingAndPaymentFile(
      tenantId: $tenantId
      agingKey: $agingKey
      paymentKey: $paymentKey
    )
  }
`;
export const publicUnderwriting = /* GraphQL */ `
  query PublicUnderwriting(
    $tenantId: ID
    $questionFlag: Boolean
    $companyInfo: AWSJSON!
    $summary: AWSJSON
  ) {
    publicUnderwriting(
      tenantId: $tenantId
      questionFlag: $questionFlag
      companyInfo: $companyInfo
      summary: $summary
    )
  }
`;
export const privateUnderwritingAlt = /* GraphQL */ `
  query PrivateUnderwritingAlt(
    $tenantId: ID
    $questionFlag: Boolean
    $companyInfo: AWSJSON!
    $summary: AWSJSON
  ) {
    privateUnderwritingAlt(
      tenantId: $tenantId
      questionFlag: $questionFlag
      companyInfo: $companyInfo
      summary: $summary
    )
  }
`;
export const privateUnderwritingDnB = /* GraphQL */ `
  query PrivateUnderwritingDnB(
    $tenantId: ID
    $questionFlag: Boolean
    $companyInfo: AWSJSON!
    $summary: AWSJSON
  ) {
    privateUnderwritingDnB(
      tenantId: $tenantId
      questionFlag: $questionFlag
      companyInfo: $companyInfo
      summary: $summary
    )
  }
`;
export const syncCompanyRecord = /* GraphQL */ `
  query SyncCompanyRecord(
    $tenantId: ID
    $companyId: ID
    $companyInfo: AWSJSON
    $summary: AWSJSON
  ) {
    syncCompanyRecord(
      tenantId: $tenantId
      companyId: $companyId
      companyInfo: $companyInfo
      summary: $summary
    )
  }
`;
export const companyDocGenerator = /* GraphQL */ `
  query CompanyDocGenerator($tenantId: ID, $data: AWSJSON) {
    companyDocGenerator(tenantId: $tenantId, data: $data)
  }
`;
export const convertExlFilesPapaya = /* GraphQL */ `
  query ConvertExlFilesPapaya(
    $tenantId: ID
    $agingKey: String
    $paymentKey: String
  ) {
    convertExlFilesPapaya(
      tenantId: $tenantId
      agingKey: $agingKey
      paymentKey: $paymentKey
    )
  }
`;
export const compareSummaries = /* GraphQL */ `
  query CompareSummaries($event: AWSJSON!) {
    compareSummaries(event: $event)
  }
`;
export const getAllSourcesData = /* GraphQL */ `
  query GetAllSourcesData($companyInfo: AWSJSON!) {
    getAllSourcesData(companyInfo: $companyInfo)
  }
`;
export const publicMultiSourceData = /* GraphQL */ `
  query PublicMultiSourceData($companyInfo: AWSJSON!, $alphaVantage: AWSJSON) {
    publicMultiSourceData(
      companyInfo: $companyInfo
      alphaVantage: $alphaVantage
    )
  }
`;
export const googleNewsCompanySearch = /* GraphQL */ `
  query GoogleNewsCompanySearch($event: AWSJSON) {
    googleNewsCompanySearch(event: $event)
  }
`;
export const salesforceDataImportApi = /* GraphQL */ `
  query SalesforceDataImportApi($companiesArray: AWSJSON!) {
    salesforceDataImportApi(companiesArray: $companiesArray)
  }
`;
export const triggerSendMitigationEmail = /* GraphQL */ `
  query TriggerSendMitigationEmail(
    $tenantName: String!
    $companyName: String!
    $customMessage: String!
    $fullName: String
    $email: String
  ) {
    triggerSendMitigationEmail(
      tenantName: $tenantName
      companyName: $companyName
      customMessage: $customMessage
      fullName: $fullName
      email: $email
    )
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($tenantId: ID!, $id: ID!) {
    getCompany(tenantId: $tenantId, id: $id) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        name
        country
        ticker
        googleSearch {
          tenantId
          id
          knowledgeGraph {
            tenantId
            id
            title
            type
            kgmid
            knowledgeGraphSearchLink
            serpapiKnowledgeGraphSearchLink
            website
            rating
            reviewCount
            address
            phone
            relatedQuestions {
              nextToken
              __typename
            }
            raw
            createdAt
            updatedAt
            __typename
          }
          organicResult {
            items {
              tenantId
              id
              position
              title
              link
              displayed_link
              favicon
              snippet
              snippet_highlighted_words
              source
              createdAt
              updatedAt
              googleSearchOrganicResultTenantId
              googleSearchOrganicResultId
              __typename
            }
            nextToken
            __typename
          }
          news {
            items {
              tenantId
              id
              position
              link
              title
              source
              date
              snippet
              thumbnail
              createdAt
              updatedAt
              googleSearchNewsTenantId
              googleSearchNewsId
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          googleSearchKnowledgeGraphTenantId
          googleSearchKnowledgeGraphId
          __typename
        }
        wikipedia {
          tenantId
          id
          content
          infobox
          traded
          createdAt
          updatedAt
          __typename
        }
        yahooFinance {
          tenantId
          id
          info
          actions
          dividends
          splits
          capital_gains
          shares
          income_stmt
          quarterly_income_stmt
          balance_sheet
          quarterly_balance_sheet
          cashflow
          quarterly_cashflow
          history
          major_holders
          institutional_holders
          mutualfund_holders
          earnings_dates
          isin
          options
          news
          createdAt
          updatedAt
          __typename
        }
        customSource
        dnbCompanyId
        requestedCreditLimit
        view
        questions {
          items {
            tenantId
            id
            question
            answer
            summary
            citations {
              nextToken
              __typename
            }
            template {
              tenantId
              id
              question
              position
              inRisk
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            companyQuestionsTenantId
            companyQuestionsId
            companyQuestionTemplateTenantId
            companyQuestionTemplateId
            __typename
          }
          nextToken
          __typename
        }
        parentCompanyName
        parentDnbCompanyId
        parentCountry
        parentTicker
        summary
        companyJsonData
        isManuallyAdded
        agingFileName
        lastOpenedAt
        manualSource
        linkedInFileName
        companyTreeData
        createdAt
        updatedAt
        companyGoogleSearchTenantId
        companyGoogleSearchId
        companyWikipediaTenantId
        companyWikipediaId
        companyYahooFinanceTenantId
        companyYahooFinanceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLastVisitedCompanies = /* GraphQL */ `
  query GetLastVisitedCompanies($tenantId: ID!, $lastOpenedAt: AWSDateTime!) {
    getLastVisitedCompanies(tenantId: $tenantId, lastOpenedAt: $lastOpenedAt) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLastVisitedCompanies = /* GraphQL */ `
  query ListLastVisitedCompanies(
    $tenantId: ID
    $lastOpenedAt: ModelStringKeyConditionInput
    $filter: ModelLastVisitedCompaniesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLastVisitedCompanies(
      tenantId: $tenantId
      lastOpenedAt: $lastOpenedAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        lastOpenedAt
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyRecord = /* GraphQL */ `
  query GetCompanyRecord($tenantId: ID!, $id: ID!) {
    getCompanyRecord(tenantId: $tenantId, id: $id) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyRecords = /* GraphQL */ `
  query ListCompanyRecords(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyRecords(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewCompanyRecord = /* GraphQL */ `
  query GetNewCompanyRecord($tenantId: ID!, $id: ID!) {
    getNewCompanyRecord(tenantId: $tenantId, id: $id) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNewCompanyRecords = /* GraphQL */ `
  query ListNewCompanyRecords(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelNewCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNewCompanyRecords(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoogleSearch = /* GraphQL */ `
  query GetGoogleSearch($tenantId: ID!, $id: ID!) {
    getGoogleSearch(tenantId: $tenantId, id: $id) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const listGoogleSearches = /* GraphQL */ `
  query ListGoogleSearches(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelGoogleSearchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGoogleSearches(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWikipedia = /* GraphQL */ `
  query GetWikipedia($tenantId: ID!, $id: ID!) {
    getWikipedia(tenantId: $tenantId, id: $id) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWikipedias = /* GraphQL */ `
  query ListWikipedias(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelWikipediaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWikipedias(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getYahooFinance = /* GraphQL */ `
  query GetYahooFinance($tenantId: ID!, $id: ID!) {
    getYahooFinance(tenantId: $tenantId, id: $id) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listYahooFinances = /* GraphQL */ `
  query ListYahooFinances(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelYahooFinanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listYahooFinances(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKnowledgeGraph = /* GraphQL */ `
  query GetKnowledgeGraph($tenantId: ID!, $id: ID!) {
    getKnowledgeGraph(tenantId: $tenantId, id: $id) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKnowledgeGraphs = /* GraphQL */ `
  query ListKnowledgeGraphs(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelKnowledgeGraphFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKnowledgeGraphs(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoogleRelatedQuestion = /* GraphQL */ `
  query GetGoogleRelatedQuestion($tenantId: ID!, $id: ID!) {
    getGoogleRelatedQuestion(tenantId: $tenantId, id: $id) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const listGoogleRelatedQuestions = /* GraphQL */ `
  query ListGoogleRelatedQuestions(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelGoogleRelatedQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGoogleRelatedQuestions(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        question
        snippet
        title
        link
        displayedLink
        thumbnail
        nextPageToken
        serpapiLink
        createdAt
        updatedAt
        knowledgeGraphRelatedQuestionsTenantId
        knowledgeGraphRelatedQuestionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoogleOrganicResult = /* GraphQL */ `
  query GetGoogleOrganicResult($tenantId: ID!, $id: ID!) {
    getGoogleOrganicResult(tenantId: $tenantId, id: $id) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const listGoogleOrganicResults = /* GraphQL */ `
  query ListGoogleOrganicResults(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelGoogleOrganicResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGoogleOrganicResults(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        position
        title
        link
        displayed_link
        favicon
        snippet
        snippet_highlighted_words
        source
        createdAt
        updatedAt
        googleSearchOrganicResultTenantId
        googleSearchOrganicResultId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyQuestion = /* GraphQL */ `
  query GetCompanyQuestion($tenantId: ID!, $id: ID!) {
    getCompanyQuestion(tenantId: $tenantId, id: $id) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const listCompanyQuestions = /* GraphQL */ `
  query ListCompanyQuestions(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyQuestions(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        question
        answer
        summary
        citations {
          items {
            tenantId
            id
            text
            link
            date
            createdAt
            updatedAt
            companyQuestionCitationsTenantId
            companyQuestionCitationsId
            __typename
          }
          nextToken
          __typename
        }
        template {
          tenantId
          id
          question
          position
          inRisk
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        companyQuestionsTenantId
        companyQuestionsId
        companyQuestionTemplateTenantId
        companyQuestionTemplateId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyCitation = /* GraphQL */ `
  query GetCompanyCitation($tenantId: ID!, $id: ID!) {
    getCompanyCitation(tenantId: $tenantId, id: $id) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const listCompanyCitations = /* GraphQL */ `
  query ListCompanyCitations(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyCitationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyCitations(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        text
        link
        date
        createdAt
        updatedAt
        companyQuestionCitationsTenantId
        companyQuestionCitationsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoogleNews = /* GraphQL */ `
  query GetGoogleNews($tenantId: ID!, $id: ID!) {
    getGoogleNews(tenantId: $tenantId, id: $id) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const listGoogleNews = /* GraphQL */ `
  query ListGoogleNews(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelGoogleNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGoogleNews(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        position
        link
        title
        source
        date
        snippet
        thumbnail
        createdAt
        updatedAt
        googleSearchNewsTenantId
        googleSearchNewsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionTemplate = /* GraphQL */ `
  query GetQuestionTemplate($tenantId: ID!, $id: ID!) {
    getQuestionTemplate(tenantId: $tenantId, id: $id) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestionTemplates = /* GraphQL */ `
  query ListQuestionTemplates(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelQuestionTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestionTemplates(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      agingUpdatedUpTo
      __typename
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isPaid
        isAREnabled
        createdAt
        updatedAt
        role
        counter
        scheduleInfo
        email
        agingUpdatedUpTo
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAPIMapping = /* GraphQL */ `
  query GetAPIMapping($tenantId: ID!, $id: ID!) {
    getAPIMapping(tenantId: $tenantId, id: $id) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAPIMappings = /* GraphQL */ `
  query ListAPIMappings(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelAPIMappingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAPIMappings(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        isPaid
        origin
        endpoint
        totalCalls
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPortfolioScoreRecord = /* GraphQL */ `
  query GetPortfolioScoreRecord($tenantId: ID!, $id: ID!) {
    getPortfolioScoreRecord(tenantId: $tenantId, id: $id) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPortfolioScoreRecords = /* GraphQL */ `
  query ListPortfolioScoreRecords(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelPortfolioScoreRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPortfolioScoreRecords(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        averagePortfolioScore
        totalValidCompanies
        totalMoneyAtRisk
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashboardPreferences = /* GraphQL */ `
  query GetDashboardPreferences($tenantId: ID!, $feature: String!) {
    getDashboardPreferences(tenantId: $tenantId, feature: $feature) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const listDashboardPreferences = /* GraphQL */ `
  query ListDashboardPreferences(
    $tenantId: ID
    $feature: ModelStringKeyConditionInput
    $filter: ModelDashboardPreferencesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDashboardPreferences(
      tenantId: $tenantId
      feature: $feature
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        feature
        createdAt
        updatedAt
        preferences
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($tenantId: ID!, $id: ID!) {
    getAlert(tenantId: $tenantId, id: $id) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlerts(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        message
        isRead
        createdAt
        updatedAt
        type
        companyInfo
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatFileCollection = /* GraphQL */ `
  query GetChatFileCollection($fileId: String!) {
    getChatFileCollection(fileId: $fileId) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatFileCollections = /* GraphQL */ `
  query ListChatFileCollections(
    $fileId: String
    $filter: ModelChatFileCollectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatFileCollections(
      fileId: $fileId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        fileId
        fileName
        fileS3Path
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatHistory = /* GraphQL */ `
  query GetChatHistory($tenantId: ID!, $chatSessionId: String!) {
    getChatHistory(tenantId: $tenantId, chatSessionId: $chatSessionId) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatHistories = /* GraphQL */ `
  query ListChatHistories(
    $tenantId: ID
    $chatSessionId: ModelStringKeyConditionInput
    $filter: ModelChatHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatHistories(
      tenantId: $tenantId
      chatSessionId: $chatSessionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        chatSessionId
        fileId
        fileName
        companyId
        messages
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlag = /* GraphQL */ `
  query GetFeatureFlag($id: ID!) {
    getFeatureFlag(id: $id) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeatureFlags = /* GraphQL */ `
  query ListFeatureFlags(
    $id: ID
    $filter: ModelFeatureFlagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeatureFlags(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        features
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesByName = /* GraphQL */ `
  query CompaniesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        country
        ticker
        googleSearch {
          tenantId
          id
          knowledgeGraph {
            tenantId
            id
            title
            type
            kgmid
            knowledgeGraphSearchLink
            serpapiKnowledgeGraphSearchLink
            website
            rating
            reviewCount
            address
            phone
            relatedQuestions {
              nextToken
              __typename
            }
            raw
            createdAt
            updatedAt
            __typename
          }
          organicResult {
            items {
              tenantId
              id
              position
              title
              link
              displayed_link
              favicon
              snippet
              snippet_highlighted_words
              source
              createdAt
              updatedAt
              googleSearchOrganicResultTenantId
              googleSearchOrganicResultId
              __typename
            }
            nextToken
            __typename
          }
          news {
            items {
              tenantId
              id
              position
              link
              title
              source
              date
              snippet
              thumbnail
              createdAt
              updatedAt
              googleSearchNewsTenantId
              googleSearchNewsId
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          googleSearchKnowledgeGraphTenantId
          googleSearchKnowledgeGraphId
          __typename
        }
        wikipedia {
          tenantId
          id
          content
          infobox
          traded
          createdAt
          updatedAt
          __typename
        }
        yahooFinance {
          tenantId
          id
          info
          actions
          dividends
          splits
          capital_gains
          shares
          income_stmt
          quarterly_income_stmt
          balance_sheet
          quarterly_balance_sheet
          cashflow
          quarterly_cashflow
          history
          major_holders
          institutional_holders
          mutualfund_holders
          earnings_dates
          isin
          options
          news
          createdAt
          updatedAt
          __typename
        }
        customSource
        dnbCompanyId
        requestedCreditLimit
        view
        questions {
          items {
            tenantId
            id
            question
            answer
            summary
            citations {
              nextToken
              __typename
            }
            template {
              tenantId
              id
              question
              position
              inRisk
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            companyQuestionsTenantId
            companyQuestionsId
            companyQuestionTemplateTenantId
            companyQuestionTemplateId
            __typename
          }
          nextToken
          __typename
        }
        parentCompanyName
        parentDnbCompanyId
        parentCountry
        parentTicker
        summary
        companyJsonData
        isManuallyAdded
        agingFileName
        lastOpenedAt
        manualSource
        linkedInFileName
        companyTreeData
        createdAt
        updatedAt
        companyGoogleSearchTenantId
        companyGoogleSearchId
        companyWikipediaTenantId
        companyWikipediaId
        companyYahooFinanceTenantId
        companyYahooFinanceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompaniesByView = /* GraphQL */ `
  query GetCompaniesByView(
    $view: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompaniesByView(
      view: $view
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        country
        ticker
        googleSearch {
          tenantId
          id
          knowledgeGraph {
            tenantId
            id
            title
            type
            kgmid
            knowledgeGraphSearchLink
            serpapiKnowledgeGraphSearchLink
            website
            rating
            reviewCount
            address
            phone
            relatedQuestions {
              nextToken
              __typename
            }
            raw
            createdAt
            updatedAt
            __typename
          }
          organicResult {
            items {
              tenantId
              id
              position
              title
              link
              displayed_link
              favicon
              snippet
              snippet_highlighted_words
              source
              createdAt
              updatedAt
              googleSearchOrganicResultTenantId
              googleSearchOrganicResultId
              __typename
            }
            nextToken
            __typename
          }
          news {
            items {
              tenantId
              id
              position
              link
              title
              source
              date
              snippet
              thumbnail
              createdAt
              updatedAt
              googleSearchNewsTenantId
              googleSearchNewsId
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          googleSearchKnowledgeGraphTenantId
          googleSearchKnowledgeGraphId
          __typename
        }
        wikipedia {
          tenantId
          id
          content
          infobox
          traded
          createdAt
          updatedAt
          __typename
        }
        yahooFinance {
          tenantId
          id
          info
          actions
          dividends
          splits
          capital_gains
          shares
          income_stmt
          quarterly_income_stmt
          balance_sheet
          quarterly_balance_sheet
          cashflow
          quarterly_cashflow
          history
          major_holders
          institutional_holders
          mutualfund_holders
          earnings_dates
          isin
          options
          news
          createdAt
          updatedAt
          __typename
        }
        customSource
        dnbCompanyId
        requestedCreditLimit
        view
        questions {
          items {
            tenantId
            id
            question
            answer
            summary
            citations {
              nextToken
              __typename
            }
            template {
              tenantId
              id
              question
              position
              inRisk
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            companyQuestionsTenantId
            companyQuestionsId
            companyQuestionTemplateTenantId
            companyQuestionTemplateId
            __typename
          }
          nextToken
          __typename
        }
        parentCompanyName
        parentDnbCompanyId
        parentCountry
        parentTicker
        summary
        companyJsonData
        isManuallyAdded
        agingFileName
        lastOpenedAt
        manualSource
        linkedInFileName
        companyTreeData
        createdAt
        updatedAt
        companyGoogleSearchTenantId
        companyGoogleSearchId
        companyWikipediaTenantId
        companyWikipediaId
        companyYahooFinanceTenantId
        companyYahooFinanceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyRecordsByName = /* GraphQL */ `
  query GetCompanyRecordsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompanyRecordsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyRecordsByView = /* GraphQL */ `
  query GetCompanyRecordsByView(
    $view: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompanyRecordsByView(
      view: $view
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewCompanyRecordsByName = /* GraphQL */ `
  query GetNewCompanyRecordsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNewCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNewCompanyRecordsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewCompanyRecordsByView = /* GraphQL */ `
  query GetNewCompanyRecordsByView(
    $view: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelNewCompanyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNewCompanyRecordsByView(
      view: $view
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        name
        companyInfo
        summary
        view
        originalItemUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
