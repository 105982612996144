import { formatCurrency } from "./utils/Helper";

export default function getExecutiveData(data) {
  const {
    company,
    summary,
    overview,
    incomeStatement,
    AgingDataForCompany,
    balanceSheet,
    linkedInJSON,
    dnBFinancialV2Data,
    dnbJsonData,
  } = data;
  var ARCScore = "";
  var legalScore = "";
  var alternativeScore = "";
  var sectorScore = "";
  var countryScore = "";
  var riskScore = "";
  var recommendedLimit = "";
  var requestedLimit = "";
  var underwritingReason = "";
  var currentArBalance = 0;
  var totalArBalance = 0;
  var termsRecommended = "";
  var currentRecommended = "";
  var paydex = "";
  var tenure = "";
  var avgCustomerPaymentDays = "";
  var arrear = 0;
  var delinquencyScore = "";
  var failureScore = "";
  var altmanZScore = "";
  var arBalance30 = 0;
  var arBalance60 = 0;
  var arBalance90 = 0;
  var lastUpdatedDate = "";

  let descriptionWikipedia = "";
  const regex = /\{\{Short description\|(.+?)\}\}/;
  const match = company.wikipedia?.content?.match(regex);
  if (match) {
     descriptionWikipedia = match[1];
  } else {
    console.log("No short description found");
  }

  const WikiInfobox = company?.wikipedia?.infobox
    ? JSON.parse(company.wikipedia.infobox)
    : {};
  const manualSource = company?.manualSource
    ? JSON.parse(company.manualSource)
    : {};
  const google = company.googleSearch?.knowledgeGraph?.raw
    ? JSON.parse(company.googleSearch.knowledgeGraph.raw)
    : {};

  const formatNumber = (number) => {
    if (
      typeof number === "string" &&
      (number.includes("US $") ||
        number.includes("$") ||
        number.includes("billion") ||
        number.includes("million") ||
        number.includes("thousand"))
    ) {
      return number;
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };
  const getDescription = () => {
    if (manualSource?.description) {
      return manualSource?.description;
    } else if (overview?.Description) {
      return overview.Description;
    } else if (linkedInJSON?.description) {
      return linkedInJSON?.description;
    } else if (descriptionWikipedia !== "") {
      return descriptionWikipedia;
    } else if (WikiInfobox?.description?.manual === true) {
      return WikiInfobox.description.value;
    } else if (WikiInfobox?.description) {
      return WikiInfobox.description;
    } else {
      return "";
    }
  };
  const getSector = () => {
    if (manualSource?.industry) {
      return manualSource?.industry;
    } else if (AgingDataForCompany?.["Entity:Industry"]) {
      return AgingDataForCompany["Entity:Industry"];
    } else if (overview?.sector) {
      return overview.sector;
    } else if (
      dnbJsonData?.organization?.primaryIndustryCode?.usSicV4Description
    ) {
      return dnbJsonData.organization.primaryIndustryCode.usSicV4Description;
    } else if (linkedInJSON?.industry) {
      return linkedInJSON.industry;
    } else if (WikiInfobox?.industry?.manual === true) {
      return WikiInfobox.industry.value;
    } else if (WikiInfobox?.industry) {
      return WikiInfobox.industry;
    } else {
      return "";
    }
  };
  const getFounded = () => {
    if (manualSource?.founded) {
      return manualSource?.founded;
    } else if (dnbJsonData?.organization?.incorporatedDate) {
      return dnbJsonData.organization?.incorporatedDate;
    } else if (company?.googleSearch?.knowledgeGraph?.raw) {
      const date = JSON.parse(company.googleSearch.knowledgeGraph.raw)?.founded;
      return date || "";
    } else if (WikiInfobox?.founded?.manual === true) {
      return WikiInfobox.founded.value;
    } else if (WikiInfobox?.foundation?.manual === true) {
      return WikiInfobox.foundation.value;
    } else if (WikiInfobox?.foundation) {
      return WikiInfobox.foundation;
    } else if (WikiInfobox?.founded) {
      return WikiInfobox.founded;
    } else {
      return "";
    }
  };

  const getSymbol = () => {
    if (manualSource?.ticker) {
      return manualSource?.ticker;
    } else if (overview?.Symbol) {
      return overview.Symbol + " + " + overview.Exchange;
    } else if (company?.ticker) {
      return company.ticker;
    } else if (WikiInfobox?.traded_as?.manual === true) {
      return WikiInfobox.traded_as.value;
    } else if (WikiInfobox?.traded_as) {
      return WikiInfobox.traded_as;
    }
    if (linkedInJSON?.stock) {
      const ticker = linkedInJSON?.stock?.ticker;
      const tickerMarket = linkedInJSON?.stock?.market;
      return `${ticker} ${tickerMarket ? `(${tickerMarket})` : ""}`;
    } else {
      return "";
    }
  };

  function getRevenue(allSources) {
    const {
      financialStatement,
      AV,
      companySearch,
      SF,
      dnbFinancials,
      wiki,
      DnbData,
    } = allSources;

    function getRevenueFromSource(source) {
      switch (source) {
        case "manual":
          if (manualSource?.revenue) {
            return formatNumber(manualSource?.revenue);
          }
          return null;
        case "financialStatement":
          return (
            financialStatement?.annualReports?.[0]?.totalRevenue ||
            financialStatement?.annualReports?.[0]?.salesRevenue ||
            financialStatement?.annualReports?.[0]?.netSales ||
            null
          );
        case "AV":
          return AV?.incomeStatement?.annualReports?.[0]?.totalRevenue || null;
        case "companySearch":
          return companySearch?.financial_data?.data?.revenue?.latest || null;
        case "SF":
          return SF?.financial_data?.latest || null;
        case "dnbFinancials":
          let revenue =
            dnbFinancials?.organization?.latestFinancials?.overview
              ?.salesRevenue || null;
          const units = dnbFinancials?.units || "SingleUnits";
          const currency =
            dnbFinancials?.organization?.latestFinancials?.currency || "USD";

          if (revenue !== null && currency === "USD") {
            if (units === "Million") {
              revenue *= 1000000;
            } else if (units === "Billion") {
              revenue *= 1000000000;
            }
          }

          if (revenue === null) {
            const yearlyRevenue =
              DnbData?.organization?.financials[0]?.yearlyRevenue || [];
            const usdRevenue = yearlyRevenue?.find(
              (item) => item.currency === "USD"
            );

            if (usdRevenue) {
              revenue = usdRevenue.value;
            }
          }

          return revenue;
        case "wiki":
          if (wiki?.revenue?.manual === true) {
            return wiki.revenue.value;
          } else if (wiki?.revenue) {
            return wiki.revenue;
          }
          return null;
        default:
          return null;
      }
    }

    const revenueSources = [
      "manual",
      "financialStatement",
      "AV",
      "companySearch",
      "SF",
      "dnbFinancials",
      "wiki",
    ];

    for (let source of revenueSources) {
      const revenue = getRevenueFromSource(source);
      if (revenue) {
        return formatNumber(revenue);
      }
    }

    return null;
  }

  const getMarketCap = () => {
    if (manualSource?.marketCap) {
      return formatNumber(manualSource?.marketCap);
    } else if (overview?.MarketCapitalization) {
      return formatNumber(overview.MarketCapitalization);
    }
  };
  const getNumberOfEmployees = () => {
    let sources = [];
    if (manualSource?.numberOfEmployees) {
      return manualSource?.numberOfEmployees;
    }
    if (linkedInJSON?.company_size && !isRange(linkedInJSON.company_size)) {
      const linkedInCount = parseEmployeeCount(linkedInJSON.company_size);
      if (linkedInCount) {
        sources.push({ source: "linkedIn", value: linkedInCount });
      }
    }

    const dnbEmployees =
      dnbJsonData?.organization?.globalUltimate?.numberOfEmployees;
    if (dnbEmployees) {
      const consolidatedEmployees = dnbEmployees.find(
        (employee) => employee.informationScopeDescription === "Consolidated"
      );
      if (consolidatedEmployees?.value) {
        sources.push({ source: "dnb", value: consolidatedEmployees.value });
      }
    }

    // Source 7: Wikipedia Infobox
    if (WikiInfobox?.num_employees) {
      const wikiEmployees = WikiInfobox.num_employees.manual
        ? WikiInfobox.num_employees.value
        : WikiInfobox.num_employees;
      if (wikiEmployees) {
        sources.push({ source: "wikipedia", value: wikiEmployees });
      }
    }

    // If no sources are available, return an empty string
    if (sources.length === 0) {
      return "";
    }
    sources.sort((a, b) => b.value - a.value);

    // If the difference between the minimum and maximum is greater than 25%, return the range
    const maxEmployees = sources[0].value;
    const minEmployees = sources[sources.length - 1].value;

    if ((maxEmployees - minEmployees) / maxEmployees > 0.25) {
      return `${minEmployees} - ${maxEmployees}`;
    }

    // Return the latest value (highest priority)
    return maxEmployees;
  };

  // Helper function to check if a LinkedIn size is a range
  const isRange = (size) => {
    return size.includes("-");
  };

  const parseEmployeeCount = (employeeRange) => {
    if (!employeeRange || !isNaN(employeeRange)) {
      return null;
    }
    employeeRange = employeeRange.replace(/employees?/i, "").trim();

    if (employeeRange.endsWith("+")) {
      const baseCount = employeeRange.replace(/\+$/, "").replace(/,/g, "");
      return parseInt(baseCount, 10);
    }

    const rangeMatch = employeeRange.match(
      /^(\d{1,3}(?:,\d{3})*)\s*-?\s*(\d{0,3}(?:,\d{3})*)$/
    );
    if (rangeMatch) {
      const minCount = parseInt(rangeMatch[1].replace(/,/g, ""), 10);
      const maxCount = rangeMatch[2]
        ? parseInt(rangeMatch[2].replace(/,/g, ""), 10)
        : minCount;
      return maxCount;
    }

    return 0;
  };
  const getCreditRating = () => {
    //todo
    //financial statement.credit rating or rating
    return "";
  };
  const getLocation = () => {
    if (manualSource?.country || manualSource?.city) {
      const location = `${manualSource?.city || ""}${
        manualSource?.city && manualSource?.country ? ", " : ""
      }${manualSource?.country || ""}`;
      return location;
    } else if (google?.headquarters) {
      return google.headquarters;
    } else if (WikiInfobox?.hq_location_country?.manual === true) {
      return (
        WikiInfobox.hq_location_country.value +
        " , " +
        WikiInfobox.hq_location_city.value
      );
    } else if (WikiInfobox?.hq_location_country) {
      return (
        WikiInfobox.hq_location_country + " , " + WikiInfobox.hq_location_city
      );
    } else if (linkedInJSON?.locations) {
      const primaryLocation = linkedInJSON?.locations.find(
        (item) => item.tag === "Primary"
      );
      return primaryLocation?.address;
    }
    if (dnbJsonData?.organization?.primaryAddress) {
      return (
        dnbJsonData?.organization?.primaryAddress.addressLocality.name +
        " , " +
        dnbJsonData?.organization?.primaryAddress.addressCountry.name
      );
    } else if (company?.country) {
      return company.country;
    } else {
      return ""; // Default to empty string if no data available
    }
  };
  const getFounders = () => {
    if (manualSource?.founders) {
      return manualSource?.founders;
    } else if (WikiInfobox?.founder?.manual === true) {
      return WikiInfobox.founder.value;
    } else if (WikiInfobox?.founder) {
      return WikiInfobox.founder;
    } else {
      return ""; 
    }
  };
  const getKeyPeople = () => {
    if (manualSource?.keyPeople) {
      return manualSource?.keyPeople;
    } else if (WikiInfobox?.key_people?.manual === true) {
      return WikiInfobox.key_people.value;
    } else if (WikiInfobox?.key_people) {
      return WikiInfobox.key_people;
    } else {
      return ""; 
    }
  };
  const getParentCompany = () => {
    if (manualSource?.parentCompany) {
      return manualSource?.parentCompany;
    } else if (WikiInfobox?.parent?.manual === true) {
      return WikiInfobox.parent.value;
    } else if (WikiInfobox?.parent) {
      return WikiInfobox.parent;
    } else {
      return "";
    }
  };
  const getWebsite = () => {
    if (manualSource?.website) {
      return manualSource?.website;
    } else if (WikiInfobox?.website?.manual) {
      return WikiInfobox?.website?.value;
    } else if (WikiInfobox?.website) {
      return WikiInfobox?.website;
    } else if (linkedInJSON?.website) {
      return linkedInJSON?.website;
    } else if (google?.website) {
      return google?.website;
    } else {
      return null;
    }
  };

  //--------------financial info--------

  const getDnbRevenueByYear = (dnbFinancials, year) => {
    if (!dnbFinancials) return null;

    const previousFinancials = Array.isArray(dnbFinancials.previousFinancials)
      ? dnbFinancials.previousFinancials
      : [];
    if (!dnbFinancials) return;
    const allFinancials = [
      dnbFinancials?.latestFinancials || [],
      ...previousFinancials,
    ];

    const matchingFinancial = allFinancials.find(
      (financial) =>
        financial &&
        financial.financialStatementToDate &&
        financial.financialStatementToDate.includes(year) &&
        financial.currency === "USD"
    );

    if (matchingFinancial && matchingFinancial.overview) {
      let salesRevenue = matchingFinancial.overview.salesRevenue;
      const units = matchingFinancial.units || "SingleUnits";

      if (units === "Million") {
        salesRevenue *= 1000000;
      } else if (units === "Billion") {
        salesRevenue *= 1000000000;
      }

      return salesRevenue;
    }

    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    if (year === lastYear && dnbJsonData) {
      const yearlyRevenue =
        dnbJsonData?.organization?.financials?.[0]?.yearlyRevenue || [];
      const usdRevenue = yearlyRevenue.find((item) => item.currency === "USD");

      if (usdRevenue) {
        return usdRevenue.value;
      }
    }

    return null;
  };
  function getFinancialRevenue(allSources) {
    const { financialStatement, AV, companySearch, wiki, SF, dnbFinancials } =
      allSources;
    let revenueByYear = {};
    const currentYear = new Date().getFullYear();
    const lastThreeYears = [currentYear - 1, currentYear - 2, currentYear - 3];
    const lastYear = currentYear - 1;

    const getRevenueFromSource = (source, year) => {
      if (year === lastYear && manualSource?.revenue) {
        return manualSource?.revenue;
      }
      if (!source) return null;
      switch (source) {
        case "financialStatement":
          return (
            financialStatement?.annualReports?.find((rep) =>
              rep?.fiscalDateEnding?.includes(year)
            )?.totalRevenue ||
            financialStatement?.annualReports?.find((rep) =>
              rep?.fiscalDateEnding?.includes(year)
            )?.salesRevenue ||
            financialStatement?.annualReports?.find((rep) =>
              rep?.fiscalDateEnding?.includes(year)
            )?.netSales
          );
        case "AV":
          return AV?.incomeStatement?.annualReports?.find((rep) =>
            rep?.fiscalDateEnding?.includes(year)
          )?.totalRevenue;
        case "companySearch":
          return companySearch?.financial_data?.data?.revenue?.[year];
        case "wiki":
          if (wiki?.revenue?.manual === true) {
            return wiki.revenue.value;
          } else if (wiki?.revenue) {
            return wiki.revenue;
          }
          return null;
        case "SF":
          return SF?.financial_data?.[year];
        case "dnbFinancials":
          return getDnbRevenueByYear(dnbFinancials?.organization, year);
        default:
          return null;
      }
    };

    let avRevenues = [];
    let dnbRevenues = [];
    let avDataAvailable = true;

    lastThreeYears.forEach((year) => {
      const avRevenue = getRevenueFromSource("AV", year);
      if (!avRevenue) avDataAvailable = false;
      avRevenues.push(avRevenue);
    });

    lastThreeYears.forEach((year) => {
      const dnbRevenue = getRevenueFromSource("dnbFinancials", year);
      dnbRevenues.push(dnbRevenue);
    });

    if (!financialStatement && !avDataAvailable) {
      const revenues = [
        getRevenueFromSource("companySearch", currentYear - 1),
        getRevenueFromSource("SF", currentYear - 1),
        getRevenueFromSource("wiki", currentYear - 1),
      ].filter(Boolean);

      if (revenues.length > 0) {
        const maxRevenue = Math.max(...revenues);
        const minRevenue = Math.min(...revenues);

        if (maxRevenue / minRevenue > 1.25) {
          revenueByYear[currentYear - 1] = `Range: ${formatNumber(
            minRevenue
          )} - ${formatNumber(maxRevenue)}`;
        } else {
          revenueByYear[currentYear - 1] = formatNumber(revenues[0]);
        }
      } else {
        lastThreeYears.forEach((year, index) => {
          const dnbRevenue = dnbRevenues[index];
          if (dnbRevenue) {
            revenueByYear[year] = formatNumber(dnbRevenue);
          } else {
            revenueByYear[year] = null;
          }
        });
      }
    } else {
      lastThreeYears.forEach((year, index) => {
        const financialStatementRevenue = getRevenueFromSource(
          "financialStatement",
          year
        );
        const avRevenue = avRevenues[index];

        if (financialStatementRevenue) {
          revenueByYear[year] = formatNumber(financialStatementRevenue);
        } else if (avRevenue) {
          revenueByYear[year] = formatNumber(avRevenue);
        } else if (year === currentYear - 1) {
          const otherRevenues = [
            getRevenueFromSource("companySearch", year),
            getRevenueFromSource("SF", year),
            getRevenueFromSource("wiki", year),
          ].filter(Boolean);
          if (otherRevenues.length > 0) {
            const maxRevenue = Math.max(...otherRevenues);
            const minRevenue = Math.min(...otherRevenues);

            if (maxRevenue / minRevenue > 1.25) {
              revenueByYear[year] = `Range: ${formatNumber(
                minRevenue
              )} - ${formatNumber(maxRevenue)}`;
            } else {
              revenueByYear[year] = formatNumber(otherRevenues[0]);
            }
          } else {
            revenueByYear[year] = null;
          }
        }
      });
    }
    return revenueByYear;
  }

  var revenues = getFinancialRevenue({
    AV: { overview, balanceSheet, incomeStatement },
    wiki: WikiInfobox,
    dnbFinancials: dnBFinancialV2Data,
  });

  //---------------financial info card------------

  var Description = getDescription();
  const Main_sector = getSector();
  const Symbol = getSymbol();
  const founded = getFounded();
  const Revenue = getRevenue({
    AV: { overview, incomeStatement },
    wiki: WikiInfobox,
    dnbFinancials: dnBFinancialV2Data,
    DnbData: dnbJsonData,
  });
  const Number_of_employees = getNumberOfEmployees();
  const Main_location_office = getLocation();
  const Market_cap = getMarketCap();
  const Credit_Rating = getCreditRating();
  const founders = getFounders();
  const Key_staff = getKeyPeople();
  const Parent_company = getParentCompany();
  const website = getWebsite();
  const main_competitors = "";

  if (summary) {
    ARCScore =
      summary["Combined Score"] && !isNaN(summary["Combined Score"])
        ? summary["Combined Score"]
        : 0;
    legalScore = parseFloat(summary["Legal Score"]);
    alternativeScore = parseFloat(summary["Alternative Score"]);
    countryScore = parseFloat(summary["Country Score"]);
    sectorScore = parseFloat(summary["Sector Score"]);
    delinquencyScore = summary["Delinquency Score"]
      ? parseFloat(summary["Delinquency Score"])
      : undefined;
    failureScore = summary["Failure Score"]
      ? parseFloat(summary["Failure Score"])
      : undefined;
    riskScore = summary["Risk Segment"]
      ? parseFloat(summary["Risk Segment"])
      : undefined;
    altmanZScore = summary["Altman Z Score"]
      ? parseFloat(summary["Altman Z Score"])
      : undefined;
    recommendedLimit = formatCurrency(summary["Recommended Limit"])
    requestedLimit = summary["Requested Limit"]
    ? formatCurrency(summary["Requested Limit"])
    : "";

    if (summary["Type Last Updated At"]) {
      var date = new Date(summary["Type Last Updated At"]);
      var day = String(date.getDate()).padStart(2, "0");
      var month = String(date.getMonth() + 1).padStart(2, "0");
      var year = date.getFullYear();

      lastUpdatedDate = `${day}/${month}/${year}`;
    }
    const sanitizeValue = (value) => {
      return isNaN(value) || value === undefined || value === null || value === "" ? 0 : value;
    };
    let arBalance30Value = sanitizeValue(summary["AR Balance Aging 30"]);
    let arBalance60Value = sanitizeValue(summary["AR Balance Aging 60"]);
    let arBalance90Value = sanitizeValue(summary["AR Balance Aging 90"]);

    arrear = formatCurrency(arBalance30Value + arBalance60Value + arBalance90Value);
    currentArBalance = formatCurrency(summary["Current AR Balance"]);
    totalArBalance = formatCurrency(summary["Total AR Balance"]);
    arBalance30 = formatCurrency(summary["AR Balance Aging 30"]);
    arBalance60 = formatCurrency(summary["AR Balance Aging 60"]);
    arBalance90 = formatCurrency(summary["AR Balance Aging 90"]);
    let recommendedNet = summary["Recommended Net"];
    if (recommendedNet && recommendedNet.startsWith("Net ")) {
      recommendedNet = recommendedNet.substring(4);
    }
    recommendedNet = parseInt(recommendedNet) || "-";
    termsRecommended = recommendedNet;

    let currentNet = summary["Current Net"];
    if (currentNet && currentNet.startsWith("Net ")) {
      currentNet = currentNet.substring(4);
    }
    currentNet = parseInt(currentNet) || "-";
    currentRecommended = currentNet;

    paydex = summary["Paydex"] ? parseInt(summary["Paydex"]) : "-";
    tenure = summary["Tenure"] ? parseInt(summary["Tenure"]) : "-";
    avgCustomerPaymentDays = summary["AVG Customer Payment Days"]
      ? summary["AVG Customer Payment Days"]
      : "-";

    underwritingReason = summary["Underwriting Reason"];
  }

  const executiveData = {
    Description: Description,
    Main_sector: Main_sector,
    Symbol: Symbol,
    Founded: founded,
    Revenue: Revenue,
    Number_of_employees: Number_of_employees,
    Main_location_office: Main_location_office,
    Market_cap: Market_cap,
    Credit_Rating: Credit_Rating,
    Founders: founders,
    Key_staff: Key_staff,
    Parent_company: Parent_company,
    Website: website,
    Main_competitors: main_competitors,
  };
  const summaryScores = {
    ARCScore: ARCScore,
    legalScore: legalScore,
    alternativeScore: alternativeScore,
    countryScore: countryScore,
    riskScore: riskScore,
    sectorScore: sectorScore,
    recommendedLimit: recommendedLimit,
    requestedLimit: requestedLimit,
    currentArBalance: currentArBalance,
    totalArBalance: totalArBalance,
    arBalance30: arBalance30,
    arBalance60: arBalance60,
    arBalance90: arBalance90,
    termsRecommended: termsRecommended,
    currentRecommended: currentRecommended,
    paydex: paydex,
    tenure: tenure,
    avgCustomerPaymentDays: avgCustomerPaymentDays,
    underwritingReason: underwritingReason,
    delinquencyScore: delinquencyScore,
    failureScore: failureScore,
    altmanZScore: altmanZScore,
    lastUpdatedDate: lastUpdatedDate,
    arrear,
  };

  const financialInfo = {
    last3yrRevenue: revenues,
  };

  return { executiveData, summaryScores, financialInfo };
}
