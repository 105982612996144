import { createContext, useContext, useEffect, useState } from "react";
import { getTenantIdAuth0, isAdminAuth0 } from "../authUtils";
import getTenantIdFromURL from "../utils/getTenantIdFromURL";

const prospectsARContext = createContext(undefined);

export const ProspectsARProvider= ({ children }) => {
  const [isProspects, setProspectValue] = useState(true); 
  const [tenantId, setTenantId] = useState(null); 

  useEffect(() => {
    const fetchTenantId = async () => {
      const isAdmin = await isAdminAuth0();
      const selectedTenant = getTenantIdFromURL();
      const tenantIdValue = isAdmin ? selectedTenant : await getTenantIdAuth0();
      setTenantId(tenantIdValue);
      const savedValue = localStorage.getItem(`prospectValue_${tenantIdValue}`);
      setProspectValue(savedValue !== null ? JSON.parse(savedValue) : true);
    };
    fetchTenantId();
  }, []);

  const setProspects=(val)=>{
    setProspectValue(val)
    localStorage.setItem(`prospectValue_${tenantId}`, JSON.stringify(val)); 
  }
  return (
    <prospectsARContext.Provider value={{ isProspects, setProspects }}>
      {children}
    </prospectsARContext.Provider>
  );
};

export const useProspectsAR = ()=> {
  const context = useContext(prospectsARContext);
  if (!context) {
    throw new Error("useProspectsAR must be used within a ProspectsARProvider");
  }
  return context;
};