/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotifyUserEvent = /* GraphQL */ `
  subscription OnNotifyUserEvent {
    onNotifyUserEvent {
      message
      tenantId
      companyName
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onCreateCompany(filter: $filter) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onUpdateCompany(filter: $filter) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onDeleteCompany(filter: $filter) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const onCreateLastVisitedCompanies = /* GraphQL */ `
  subscription OnCreateLastVisitedCompanies(
    $filter: ModelSubscriptionLastVisitedCompaniesFilterInput
  ) {
    onCreateLastVisitedCompanies(filter: $filter) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLastVisitedCompanies = /* GraphQL */ `
  subscription OnUpdateLastVisitedCompanies(
    $filter: ModelSubscriptionLastVisitedCompaniesFilterInput
  ) {
    onUpdateLastVisitedCompanies(filter: $filter) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLastVisitedCompanies = /* GraphQL */ `
  subscription OnDeleteLastVisitedCompanies(
    $filter: ModelSubscriptionLastVisitedCompaniesFilterInput
  ) {
    onDeleteLastVisitedCompanies(filter: $filter) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompanyRecord = /* GraphQL */ `
  subscription OnCreateCompanyRecord(
    $filter: ModelSubscriptionCompanyRecordFilterInput
  ) {
    onCreateCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyRecord = /* GraphQL */ `
  subscription OnUpdateCompanyRecord(
    $filter: ModelSubscriptionCompanyRecordFilterInput
  ) {
    onUpdateCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyRecord = /* GraphQL */ `
  subscription OnDeleteCompanyRecord(
    $filter: ModelSubscriptionCompanyRecordFilterInput
  ) {
    onDeleteCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNewCompanyRecord = /* GraphQL */ `
  subscription OnCreateNewCompanyRecord(
    $filter: ModelSubscriptionNewCompanyRecordFilterInput
  ) {
    onCreateNewCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNewCompanyRecord = /* GraphQL */ `
  subscription OnUpdateNewCompanyRecord(
    $filter: ModelSubscriptionNewCompanyRecordFilterInput
  ) {
    onUpdateNewCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNewCompanyRecord = /* GraphQL */ `
  subscription OnDeleteNewCompanyRecord(
    $filter: ModelSubscriptionNewCompanyRecordFilterInput
  ) {
    onDeleteNewCompanyRecord(filter: $filter) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGoogleSearch = /* GraphQL */ `
  subscription OnCreateGoogleSearch(
    $filter: ModelSubscriptionGoogleSearchFilterInput
  ) {
    onCreateGoogleSearch(filter: $filter) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const onUpdateGoogleSearch = /* GraphQL */ `
  subscription OnUpdateGoogleSearch(
    $filter: ModelSubscriptionGoogleSearchFilterInput
  ) {
    onUpdateGoogleSearch(filter: $filter) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const onDeleteGoogleSearch = /* GraphQL */ `
  subscription OnDeleteGoogleSearch(
    $filter: ModelSubscriptionGoogleSearchFilterInput
  ) {
    onDeleteGoogleSearch(filter: $filter) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const onCreateWikipedia = /* GraphQL */ `
  subscription OnCreateWikipedia(
    $filter: ModelSubscriptionWikipediaFilterInput
  ) {
    onCreateWikipedia(filter: $filter) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateWikipedia = /* GraphQL */ `
  subscription OnUpdateWikipedia(
    $filter: ModelSubscriptionWikipediaFilterInput
  ) {
    onUpdateWikipedia(filter: $filter) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteWikipedia = /* GraphQL */ `
  subscription OnDeleteWikipedia(
    $filter: ModelSubscriptionWikipediaFilterInput
  ) {
    onDeleteWikipedia(filter: $filter) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateYahooFinance = /* GraphQL */ `
  subscription OnCreateYahooFinance(
    $filter: ModelSubscriptionYahooFinanceFilterInput
  ) {
    onCreateYahooFinance(filter: $filter) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateYahooFinance = /* GraphQL */ `
  subscription OnUpdateYahooFinance(
    $filter: ModelSubscriptionYahooFinanceFilterInput
  ) {
    onUpdateYahooFinance(filter: $filter) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteYahooFinance = /* GraphQL */ `
  subscription OnDeleteYahooFinance(
    $filter: ModelSubscriptionYahooFinanceFilterInput
  ) {
    onDeleteYahooFinance(filter: $filter) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateKnowledgeGraph = /* GraphQL */ `
  subscription OnCreateKnowledgeGraph(
    $filter: ModelSubscriptionKnowledgeGraphFilterInput
  ) {
    onCreateKnowledgeGraph(filter: $filter) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateKnowledgeGraph = /* GraphQL */ `
  subscription OnUpdateKnowledgeGraph(
    $filter: ModelSubscriptionKnowledgeGraphFilterInput
  ) {
    onUpdateKnowledgeGraph(filter: $filter) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteKnowledgeGraph = /* GraphQL */ `
  subscription OnDeleteKnowledgeGraph(
    $filter: ModelSubscriptionKnowledgeGraphFilterInput
  ) {
    onDeleteKnowledgeGraph(filter: $filter) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGoogleRelatedQuestion = /* GraphQL */ `
  subscription OnCreateGoogleRelatedQuestion(
    $filter: ModelSubscriptionGoogleRelatedQuestionFilterInput
  ) {
    onCreateGoogleRelatedQuestion(filter: $filter) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const onUpdateGoogleRelatedQuestion = /* GraphQL */ `
  subscription OnUpdateGoogleRelatedQuestion(
    $filter: ModelSubscriptionGoogleRelatedQuestionFilterInput
  ) {
    onUpdateGoogleRelatedQuestion(filter: $filter) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const onDeleteGoogleRelatedQuestion = /* GraphQL */ `
  subscription OnDeleteGoogleRelatedQuestion(
    $filter: ModelSubscriptionGoogleRelatedQuestionFilterInput
  ) {
    onDeleteGoogleRelatedQuestion(filter: $filter) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const onCreateGoogleOrganicResult = /* GraphQL */ `
  subscription OnCreateGoogleOrganicResult(
    $filter: ModelSubscriptionGoogleOrganicResultFilterInput
  ) {
    onCreateGoogleOrganicResult(filter: $filter) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const onUpdateGoogleOrganicResult = /* GraphQL */ `
  subscription OnUpdateGoogleOrganicResult(
    $filter: ModelSubscriptionGoogleOrganicResultFilterInput
  ) {
    onUpdateGoogleOrganicResult(filter: $filter) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const onDeleteGoogleOrganicResult = /* GraphQL */ `
  subscription OnDeleteGoogleOrganicResult(
    $filter: ModelSubscriptionGoogleOrganicResultFilterInput
  ) {
    onDeleteGoogleOrganicResult(filter: $filter) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const onCreateCompanyQuestion = /* GraphQL */ `
  subscription OnCreateCompanyQuestion(
    $filter: ModelSubscriptionCompanyQuestionFilterInput
  ) {
    onCreateCompanyQuestion(filter: $filter) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const onUpdateCompanyQuestion = /* GraphQL */ `
  subscription OnUpdateCompanyQuestion(
    $filter: ModelSubscriptionCompanyQuestionFilterInput
  ) {
    onUpdateCompanyQuestion(filter: $filter) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const onDeleteCompanyQuestion = /* GraphQL */ `
  subscription OnDeleteCompanyQuestion(
    $filter: ModelSubscriptionCompanyQuestionFilterInput
  ) {
    onDeleteCompanyQuestion(filter: $filter) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const onCreateCompanyCitation = /* GraphQL */ `
  subscription OnCreateCompanyCitation(
    $filter: ModelSubscriptionCompanyCitationFilterInput
  ) {
    onCreateCompanyCitation(filter: $filter) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const onUpdateCompanyCitation = /* GraphQL */ `
  subscription OnUpdateCompanyCitation(
    $filter: ModelSubscriptionCompanyCitationFilterInput
  ) {
    onUpdateCompanyCitation(filter: $filter) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const onDeleteCompanyCitation = /* GraphQL */ `
  subscription OnDeleteCompanyCitation(
    $filter: ModelSubscriptionCompanyCitationFilterInput
  ) {
    onDeleteCompanyCitation(filter: $filter) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const onCreateGoogleNews = /* GraphQL */ `
  subscription OnCreateGoogleNews(
    $filter: ModelSubscriptionGoogleNewsFilterInput
  ) {
    onCreateGoogleNews(filter: $filter) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const onUpdateGoogleNews = /* GraphQL */ `
  subscription OnUpdateGoogleNews(
    $filter: ModelSubscriptionGoogleNewsFilterInput
  ) {
    onUpdateGoogleNews(filter: $filter) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const onDeleteGoogleNews = /* GraphQL */ `
  subscription OnDeleteGoogleNews(
    $filter: ModelSubscriptionGoogleNewsFilterInput
  ) {
    onDeleteGoogleNews(filter: $filter) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const onCreateQuestionTemplate = /* GraphQL */ `
  subscription OnCreateQuestionTemplate(
    $filter: ModelSubscriptionQuestionTemplateFilterInput
  ) {
    onCreateQuestionTemplate(filter: $filter) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateQuestionTemplate = /* GraphQL */ `
  subscription OnUpdateQuestionTemplate(
    $filter: ModelSubscriptionQuestionTemplateFilterInput
  ) {
    onUpdateQuestionTemplate(filter: $filter) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteQuestionTemplate = /* GraphQL */ `
  subscription OnDeleteQuestionTemplate(
    $filter: ModelSubscriptionQuestionTemplateFilterInput
  ) {
    onDeleteQuestionTemplate(filter: $filter) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant($filter: ModelSubscriptionTenantFilterInput) {
    onCreateTenant(filter: $filter) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      agingUpdatedUpTo
      __typename
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant($filter: ModelSubscriptionTenantFilterInput) {
    onUpdateTenant(filter: $filter) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      agingUpdatedUpTo
      __typename
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant($filter: ModelSubscriptionTenantFilterInput) {
    onDeleteTenant(filter: $filter) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      agingUpdatedUpTo
      __typename
    }
  }
`;
export const onCreateAPIMapping = /* GraphQL */ `
  subscription OnCreateAPIMapping(
    $filter: ModelSubscriptionAPIMappingFilterInput
  ) {
    onCreateAPIMapping(filter: $filter) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAPIMapping = /* GraphQL */ `
  subscription OnUpdateAPIMapping(
    $filter: ModelSubscriptionAPIMappingFilterInput
  ) {
    onUpdateAPIMapping(filter: $filter) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAPIMapping = /* GraphQL */ `
  subscription OnDeleteAPIMapping(
    $filter: ModelSubscriptionAPIMappingFilterInput
  ) {
    onDeleteAPIMapping(filter: $filter) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePortfolioScoreRecord = /* GraphQL */ `
  subscription OnCreatePortfolioScoreRecord(
    $filter: ModelSubscriptionPortfolioScoreRecordFilterInput
  ) {
    onCreatePortfolioScoreRecord(filter: $filter) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePortfolioScoreRecord = /* GraphQL */ `
  subscription OnUpdatePortfolioScoreRecord(
    $filter: ModelSubscriptionPortfolioScoreRecordFilterInput
  ) {
    onUpdatePortfolioScoreRecord(filter: $filter) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePortfolioScoreRecord = /* GraphQL */ `
  subscription OnDeletePortfolioScoreRecord(
    $filter: ModelSubscriptionPortfolioScoreRecordFilterInput
  ) {
    onDeletePortfolioScoreRecord(filter: $filter) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDashboardPreferences = /* GraphQL */ `
  subscription OnCreateDashboardPreferences(
    $filter: ModelSubscriptionDashboardPreferencesFilterInput
  ) {
    onCreateDashboardPreferences(filter: $filter) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const onUpdateDashboardPreferences = /* GraphQL */ `
  subscription OnUpdateDashboardPreferences(
    $filter: ModelSubscriptionDashboardPreferencesFilterInput
  ) {
    onUpdateDashboardPreferences(filter: $filter) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const onDeleteDashboardPreferences = /* GraphQL */ `
  subscription OnDeleteDashboardPreferences(
    $filter: ModelSubscriptionDashboardPreferencesFilterInput
  ) {
    onDeleteDashboardPreferences(filter: $filter) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert($filter: ModelSubscriptionAlertFilterInput) {
    onCreateAlert(filter: $filter) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert($filter: ModelSubscriptionAlertFilterInput) {
    onUpdateAlert(filter: $filter) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert($filter: ModelSubscriptionAlertFilterInput) {
    onDeleteAlert(filter: $filter) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const onCreateChatFileCollection = /* GraphQL */ `
  subscription OnCreateChatFileCollection(
    $filter: ModelSubscriptionChatFileCollectionFilterInput
  ) {
    onCreateChatFileCollection(filter: $filter) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChatFileCollection = /* GraphQL */ `
  subscription OnUpdateChatFileCollection(
    $filter: ModelSubscriptionChatFileCollectionFilterInput
  ) {
    onUpdateChatFileCollection(filter: $filter) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChatFileCollection = /* GraphQL */ `
  subscription OnDeleteChatFileCollection(
    $filter: ModelSubscriptionChatFileCollectionFilterInput
  ) {
    onDeleteChatFileCollection(filter: $filter) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChatHistory = /* GraphQL */ `
  subscription OnCreateChatHistory(
    $filter: ModelSubscriptionChatHistoryFilterInput
  ) {
    onCreateChatHistory(filter: $filter) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChatHistory = /* GraphQL */ `
  subscription OnUpdateChatHistory(
    $filter: ModelSubscriptionChatHistoryFilterInput
  ) {
    onUpdateChatHistory(filter: $filter) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChatHistory = /* GraphQL */ `
  subscription OnDeleteChatHistory(
    $filter: ModelSubscriptionChatHistoryFilterInput
  ) {
    onDeleteChatHistory(filter: $filter) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeatureFlag = /* GraphQL */ `
  subscription OnCreateFeatureFlag(
    $filter: ModelSubscriptionFeatureFlagFilterInput
  ) {
    onCreateFeatureFlag(filter: $filter) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFeatureFlag = /* GraphQL */ `
  subscription OnUpdateFeatureFlag(
    $filter: ModelSubscriptionFeatureFlagFilterInput
  ) {
    onUpdateFeatureFlag(filter: $filter) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFeatureFlag = /* GraphQL */ `
  subscription OnDeleteFeatureFlag(
    $filter: ModelSubscriptionFeatureFlagFilterInput
  ) {
    onDeleteFeatureFlag(filter: $filter) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
